import styled, { css } from 'styled-components';
import * as theme from './Variables';

export const AboutUsDropdownItemWrapper = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

export const AboutUsDropdownItemNumber = styled.p`
	max-width: 20%;
	width: 100%;
	top: -20px;
	position: relative;
	margin: 0 auto;
	font-size: 140px;
	color: ${theme.idxNumber};
	font-family: PFD-B;
	cursor: pointer;
	@media (max-width: 992px) {
		max-width: 100%;
		text-align: center;
	}
`;

export const AboutUsDropdownItemText = styled.div`
	max-width: 70%;
	width: 100%;
	position: relative;
	border-bottom: 4px solid ${theme.idxNumber};
	padding: 30px 0 50px 0;
	text-align: center;
	line-height: 1.4;
	@media (max-width: 992px) {
		max-width: 100%;
	}
`;

export const AboutUsDropdownItemTextTitle = styled.div`
	font-size: 30px;
	font-family: C-B;
	text-align: center;
	cursor: pointer;
	${(props) =>
		!props.collapsed &&
		css`
			color: ${theme.blueish};
		`}
`;

export const AboutUsDropdownItemTextDesc = styled.div`
	overflow: hidden;
	transition: all 0.25s ease-out;
	height: auto;
	transform: scaleY(1);
	transform-origin: top;
	padding: 50px;
	line-height: 1.4;
	${(props) =>
		props.collapsed &&
		css`
			transition: all 0.25s ease-out;
			transform: scaleY(0);
			height: 0;
		`}
	@media(max-width: 992px) {
		padding: 20px 0;
	}
`;

export const AboutUsDropdownItemTextButton = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 50%;
	color: ${theme.idxNumber};
	height: 40px;
	border: 2px solid ${theme.idxNumber};
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	@media (max-width: 992px) {
		top: auto;
		right: 0;
		left: 0;
		bottom: 10px;
	}
`;

export const AboutUsTeamContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const AboutUsTeamMemberWrapper = styled.div`
	max-width: 32%;
	width: 440px;
	height: 440px;
	@media (max-width: 768px) {
		width: 100%;
		max-width: 100%;
		margin: 15px 0;
		order: 2;
		height: fit-content;
	}
`;
export const AboutUsTeamRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 30px 0;
	@media (max-width: 768px) {
		flex-direction: column;
		&:first-of-type {
			& div:last-of-type {
				order: 1;
			}
		}
	}
`;

export const AboutUsTeamMemberNormalWrapper = styled.div`
	border-radius: 50px;
	width: 440px;
	height: 440px;
	max-width: 100%;
	background: url(${(props) => props.pic});
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 50px;
	p {
		font-family: C-B;
		font-size: 24px;
		color: white;
	}
	span {
		font-size: 16px;
	}
	@media (max-width: 450px) {
		width: 100%;
		height: calc(100vw - 20px);
	}
`;

export const AboutUsTeamMemberTextWrapper = styled.div`
	padding: 50px 0 0 50px;
	font-size: 30px;
	font-family: C-B;
	div {
		margin-top: 50px;
		line-height: 1.4;
	}
	& > p {
		position: relative;
	}
	& > p:after {
		width: 100%;
		height: 10px;
		content: '';
		background: url(${(props) => props.pic});
		background-repeat: no-repeat;
		position: absolute;
		bottom: -15px;
		left: 0;
		@media (max-width: 768px) {
			right: 0;
			background-position: center;
		}
	}
	@media (max-width: 992px) {
		font-size: 20px;
	}
	@media (max-width: 768px) {
		padding: 0;
		text-align: center;
	}
`;

export const AboutUsTeamMemberJoinWrapper = styled.div`
	font-size: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	div {
		margin: 15px 0;
	}
	@media (max-width: 768px) {
		text-align: center;
	}
`;
