import React from 'react';

const ServicesTitle = () => (
	<>
		<div className='lead'>
			<p>Minden ami</p>
			<p>
				<strong>web</strong>
			</p>
		</div>
		<div className='sub'>
			<p>
				Személyre szabott szoftverfejlesztési megoldásokkal állunk
				rendelkezésedre, amely tartalmazza a szoftver tervezését, reszponzív
				felhasználói felület (UI) és tapasztalat (UX) felépítését,
				projektmenedzsmentet, minőségbiztosítást (a projekt megvalósulása után
				sem engedjük el kezedet) és az elkészült alkalmazás üzemeltetését is.
			</p>
		</div>
	</>
);

export default ServicesTitle;
