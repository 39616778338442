import Unifit_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-unifit.jpg';
import Unifit_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-unifit.jpg';
import Unifit_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-unifit.jpg';

import Deac_v1_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-deac-2018.jpg';
import Deac_v1_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-deac-2018.jpg';
import Deac_v1_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-deac-2018.jpg';

import Deac_v2_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-deac-2020.jpg';
import Deac_v2_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-deac-2020.jpg';
import Deac_v2_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-deac-2020.jpg';

import Deac_ka_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-dka.jpg';
import Deac_ka_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-dka.jpg';
import Deac_ka_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-dka.jpg';

import Dvsc_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-dvsc.jpg';
import Dvsc_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-dvsc.jpg';
import Dvsc_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-dvsc.jpg';

import Suzuki_web_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-suzuki.jpg';
import Suzuki_web_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-suzuki.jpg';
import Suzuki_web_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-suzuki.jpg';

import Tgdental_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-tgdental.jpg';
import Tgdental_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-tgdental.jpg';
import Tgdental_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-tgdental.jpg';

import Vermix_main from '../../../../assets/images/ourWorksSub/pic-munkaink-main-vermix.jpg';
import Vermix_top from '../../../../assets/images/ourWorksSub/pic-munkaink-top-vermix.jpg';
import Vermix_bottom from '../../../../assets/images/ourWorksSub/pic-munkaink-bottom-vermix.jpg';

import Suzuki_graphics_2018_left from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2018-1.jpg';
import Suzuki_graphics_2018_right from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2018-2.jpg';

import Suzuki_graphics_2019_left from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2019-1.jpg';
import Suzuki_graphics_2019_right from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2019-2.jpg';

import Suzuki_graphics_2020_left from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2020-1.jpg';
import Suzuki_graphics_2020_right from '../../../../assets/images/ourWorksSub/pic-munkaink-grafika-flup-2020-2.jpg';

export default [
	{
		type: 'web',
		link: 'unifit',
		title: 'UniFit Fitness & Gym Center',
		title_type: 'weboldalának elkészítése',
		main_img: { Unifit_main },
		sub_img_first: { Unifit_top },
		sub_img_second: { Unifit_bottom },
		lead:
			'Fealadtunk Debrecen legnagyobb és legmodernebb edzőtermének weboldalának elkészítése volt.',
		text:
			'<p>Folyamatos megbízóként az UniFit weboldalát a mai napig karbantartjuk és fejlesztjük. 2019-ben az oldal átesett egy arculati ráncfelvarráson és hamarosan érkezik a 2.0-ás verzió mivel a kezdetben elkészített rendszer mára már nem szolgálja ki azt a rengeteg szolgáltatást amivel az évek folyamán bővült a terem.</p><p>Köszönjük partenrünk bizalmát, hogy a kezdetek óta közösen visszük előre a terem sikereit.</p>',
		overview: ['MSE Klaszter', '2017-től folyamatos együttműködés', '3 hónap'],
		tasks: ['Arculattervezés', 'frontend', 'backend', 'social média'],
	},
	{
		type: 'web',
		link: 'deac-v1',
		title: 'Debreceni Egyetem Atlétikai Club 1.0-ás',
		title_type: 'weboldalának fejlesztése',
		main_img: { Deac_v1_main },
		sub_img_first: { Deac_v1_top },
		sub_img_second: { Deac_v1_bottom },
		lead:
			'A Debreceni Egyetem Atlétikai Clubjának volt szerencsénk elkészíteni első funkcionális weboldalát.',
		text:
			'<p>2017-ben kaptuk a lehetőséget, hogy az akkor már több éve működtetett kezdetleges statikus oldaluk helyet álmodjunk meg valami teljesen mást. Ekkor kerültek először felvezetésre a szakosztályok és itt került elsőnek beépítve a mécs kezelő rendszer, ahol szakosztályonként lehetett már tájékozódni az aktuális eredményekről.</p><p>Köszönjük partenrünk bizalmát, hogy 2018 óta közösen visszük előre a klub sikereit.</p>',
		overview: ['DEAC Kft.', '2018-tól folyamatos együttműködés', '3 hónap'],
		tasks: ['Arculattervezés', 'frontend', 'backend'],
	},
	{
		type: 'web',
		link: 'deac-v2',
		title: 'Debreceni Egyetem Atlétikai Club 2.0-ás',
		title_type: 'weboldalának fejlesztése',
		main_img: { Deac_v2_main },
		sub_img_first: { Deac_v2_top },
		sub_img_second: { Deac_v2_bottom },
		lead:
			'A Debreceni Egyetem Atlétikai Clubjának arculati és strukturális újra tervezése.',
		text:
			'<p>A 2017-ben 1.0-ás weboldal elkészítése óta eltelt strukturális változások a klub körül tette szükségessé, hogy egy teljesen új oldalt készítsünk el számukra és ha már belefogtunk akkor az arculatán is frissítettünk. WordPress alapokon hoztunk létre egy multisite-ot ahol a szakosztályok most már egy teljesen önálló részét képezik a fő DEAC oldalnak. Mostantól lehetőség nyílik a szakosztályok saját arculati elemeinek integrálása szakosztályi szinten megkülönböztetve az anyaoldaltól.</p><p>Köszönjük partenrünk bizalmát, hogy 2018 óta közösen visszük előre a klub sikereit.</p>',
		overview: ['DEAC Kft.', '2020', '6 hónap'],
		tasks: ['Arculattervezés', 'frontend', 'backend'],
	},
	{
		type: 'web',
		link: 'deac-ka',
		title: 'DEAC Kosárlabda Akadémia',
		title_type: 'weboldalának fejlesztése',
		main_img: { Deac_ka_main },
		sub_img_first: { Deac_ka_top },
		sub_img_second: { Deac_ka_bottom },
		lead:
			'A városi kosárlabda akadémia kiemelt akadémiává minősítésével elérkezettnek látták a szervezet vezetői egy új oldal megvalósítását.',
		text:
			'<p>Feladatunk egy új oldal létrehozása a régi elemeket szemelőt tartva. Mivel az akadémia a DEAC család részét képezi így adta magát a helyzet, hogy az év elején elkészült multisite család alá integráljuk az új oldalt.</p><p>Köszönjük partnerünk bizalmát és igyekszünk folyamatosan segíteni munkájukat.</p>',
		overview: ['DEAC Kosárlabda Akadémia', '2020', '1 hónap'],
		tasks: ['Arculattervezés', 'frontend', 'backend'],
	},
	{
		type: 'web',
		link: 'dvsc',
		title: 'DVSC Zrt.',
		title_type: 'weboldalának fejlesztése',
		main_img: { Dvsc_main },
		sub_img_first: { Dvsc_top },
		sub_img_second: { Dvsc_bottom },
		lead:
			'Szerencsésnek mondhatjuk magunkat, hogy pályafutásunk alatt Magyarország egyik legpatinásabb klubja is a megbízóink táborát	gyarapítja.',
		text:
			'<p>Feladatunk volt a sok éves múlttal rendelkező labdarúgó klub weboldalának újragondolása. Igyekeztünk szem előtt tartani a rajongói igényeket és a kor elvárásait. A visszajelzések alapján nem lőttünk mellé, de folyamatosan azon dolgozunk, hogy a legmagasabb felhasználói élményt nyújthassa a csapat az interneten keresztül.</p><p>Köszönjük partenrünk bizalmát és igyekszünk folyamatos támogatást nyújtani a csapat számára.</p>',
		overview: ['DVSC Zrt.', '2020', '4 hónap'],
		tasks: ['Arculattervezés', 'frontend', 'backend'],
	},
	{
		type: 'web',
		link: 'suzuki-web',
		title: 'Magyar Suzuki Zrt.',
		title_type: 'weboldalának fejlesztése',
		main_img: { Suzuki_web_main },
		sub_img_first: { Suzuki_web_top },
		sub_img_second: { Suzuki_web_bottom },
		lead:
			'A Magyar Suzuki Zrt. motor divíziója számára készítettünk egy 6 országot összekötő weboldal hálózatot.',
		text:
			'<p>Feladatul kaptuk a Suzuki részéről, hogy az export országokat kiszolgáló lokális weboldalak helyett hozzunk létre egy, mindenben megegyező felületet, hogy minden leendő Suzuki motortulajdonos ugyan azt az információt kaphassa meg függetlenül attól, hogy az illető Csehországban, Romániában esetleg Szlovákiában érdeklődik a kiszemelt modell iránt.</p><p>Köszönjük partnerünk bizalmát és igyekszünk folyamatosan segíteni munkájukat.</p>',
		overview: ['Magyar Suzuki Zrt.', '2020', '5 hónap'],
		tasks: [
			'Arculattervezés',
			'frontend',
			'backend',
			'szerver üzemeltetés',
			'karbantartás',
			'fejlesztések',
		],
	},
	{
		type: 'web',
		link: 'tgdental',
		title: 'TG Dental fogorvosi rendelő',
		title_type: 'weboldalának fejlesztése',
		main_img: { Tgdental_main },
		sub_img_first: { Tgdental_top },
		sub_img_second: { Tgdental_bottom },
		lead:
			'Budapesti ügyfelünknek készítettük el friss rendelőjéhez a weboldalát.',
		text:
			'<p>Frissen nyílt fogászati rendelőnek készítettünk új weboldalt mellyel a páciensek a nap minden órájában online tudnak bejelentkezni a kívánt kezelésekre. További feladatként volt szerencsénk a rendelő teljes infrastruktúráját beszerezni majd üzembe helyezni. Mert mi még ilyeneket is képesek vagyunk megoldani.</p><p>Köszönjük partnerünk bizalmát.</p>',
		overview: ['TG Dental Kft.', '2018', '2 hónap'],
		tasks: [
			'Arculattervezés',
			'frontend',
			'backend',
			'eszközbeszerzés',
			'hálózatépítés',
			'kamerarendszer kiépítés',
		],
	},
	{
		type: 'web',
		link: 'vermix',
		title: 'Vermix Hungary Kft.',
		title_type: 'weboldalának fejlesztése',
		main_img: { Vermix_main },
		sub_img_first: { Vermix_top },
		sub_img_second: { Vermix_bottom },
		lead:
			'A VerMix Hungary Kft. megbízásából bevezettük gyógykozmetikumaikat az online piactérre.',
		text:
			'<p>Évek óta a piacon voltak már polcos termékként, de elérkezettnek látták az időt az online téren történő megjelenésnek melyben segítségükre voltunk mind a webáruház létrehozásában mind a social média világában. Idő közben felfüggeszteni kényszerültek szolgáltatásukat, de 2020-ban (legkésőbb 2021-ben) újult erővel vágunk bele a problémás bőrű emberek megsegítésébe.</p><p>Köszönjük partnerünk bizalmát.</p>',
		overview: ['VerMix Hungary Kft.', '2019', '2 hónap'],
		tasks: [
			'Arculattervezés',
			'frontend',
			'backend',
			'webáruház beüzemelés',
			'termékleírások készítése',
			'online fizetés integrálása',
			'értékesítési csatornák kiépítése',
			'social media',
		],
	},
	{
		type: 'graphics',
		link: 'suzuki-katalogus-2018',
		title: 'SUZUKI Éves Katalógus (2018)',
		title_type: 'grafikai tervezés, nyomdai kivitelezés',
		main_img: { Suzuki_graphics_2018_left },
		sub_img_first: { Suzuki_graphics_2018_right },
		lead:
			'Együttműködésünk kezdetétől megkaptuk a felkérést az éves katalógus grafikai kivitelezésére melyre minden évben 7 nyelvre lefordítva kerül sor.',
		text:
			'<p>Az anyag nyomdakész elkészítésén túl a végső produktumot is mi készítjük a Suzuki számára már harmadik éve. Partneri hálózatunk lehetővé teszi, hogy a grafika megvalósításon túl a közel 1,5 tonnányi végterméket 8 országba 20 kereskedésbe sérülés mentesen, határidőre eljuttassuk.</p><p>Köszönjük partenrünk bizalmát.</p>',
		overview: [
			'Magyar Suzuki Zrt.',
			'2018',
			'1 hónap<br/>Tervezéstől a kézhezvételig',
		],
		tasks: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logiszitka',
		],
	},
	{
		type: 'graphics',
		link: 'suzuki-katalogus-2019',
		title: 'SUZUKI Éves Katalógus (2019)',
		title_type: 'grafikai tervezés, nyomdai kivitelezés',
		main_img: { Suzuki_graphics_2019_left },
		sub_img_first: { Suzuki_graphics_2019_right },
		lead:
			'Együttműködésünk kezdetétől megkaptuk a felkérést az éves katalógus grafikai kivitelezésére melyre minden évben 7 nyelvre lefordítva kerül sor.',
		text:
			'<p>Az anyag nyomdakész elkészítésén túl a végső produktumot is mi készítjük a Suzuki számára már harmadik éve. Partneri hálózatunk lehetővé teszi, hogy a grafika megvalósításon túl a közel 1,5 tonnányi végterméket 8 országba 20 kereskedésbe sérülés mentesen, határidőre eljuttassuk.</p><p>Köszönjük partenrünk bizalmát.</p>',
		overview: [
			'Magyar Suzuki Zrt.',
			'2019',
			'1 hónap<br/>Tervezéstől a kézhezvételig',
		],
		tasks: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logiszitka',
		],
	},
	{
		type: 'graphics',
		link: 'suzuki-katalogus-2020',
		title: 'SUZUKI Éves Katalógus (2020)',
		title_type: 'grafikai tervezés, nyomdai kivitelezés',
		main_img: { Suzuki_graphics_2020_left },
		sub_img_first: { Suzuki_graphics_2020_right },
		lead:
			'Együttműködésünk kezdetétől megkaptuk a felkérést az éves katalógus grafikai kivitelezésére melyre minden évben 7 nyelvre lefordítva kerül sor.',
		text:
			'<p>Az anyag nyomdakész elkészítésén túl a végső produktumot is mi készítjük a Suzuki számára már harmadik éve. Partneri hálózatunk lehetővé teszi, hogy a grafika megvalósításon túl a közel 1,5 tonnányi végterméket 8 országba 20 kereskedésbe sérülés mentesen, határidőre eljuttassuk.</p><p>Köszönjük partenrünk bizalmát.</p>',
		overview: [
			'Magyar Suzuki Zrt.',
			'2020',
			'1 hónap<br/>Tervezéstől a kézhezvételig',
		],
		tasks: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logiszitka',
		],
	},
];
