import React, { useReducer } from 'react';
import SiteBgContext from './siteBgContext';
import siteBgReducer from './siteBgReducer';
import { SET_SITE_BG } from '../types';
import bgImage from '../../assets/images/bg/bg.png';

const SiteBgState = (props) => {
	const initialState = {
		site_bg: bgImage,
	};

	const [state, dispatch] = useReducer(siteBgReducer, initialState);

	//set active bg
	const setActiveBg = (bg) => {
		dispatch({
			type: SET_SITE_BG,
			payload: bg,
		});
	};

	return (
		<SiteBgContext.Provider
			value={{
				site_bg: state.site_bg,
				setActiveBg,
			}}
		>
			{props.children}
		</SiteBgContext.Provider>
	);
};

export default SiteBgState;
