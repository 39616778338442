import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
	ContactFieldContainer,
	ContactFieldAndButtonContainer,
	SendingButton,
} from '../../styles/ContactUs.style';
import {
	TextField,
	createMuiTheme,
	ThemeProvider,
	CircularProgress,
} from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import * as theme from '../../styles/Variables';
import MessageSender from "./functions/MessageSender.function";

const ContactFormComp = () => {
	const [sending, setSending] = useState(false);
	const [buttonColor, setButtonColor] = useState(theme.primary_color);
	const [buttonText, setButtonText] = useState("üzenet küldése");
	const [ButtonIcon, setButtonIcon] = useState(SendRoundedIcon);
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	const MuiTheme = createMuiTheme({
		typography: {
			fontFamily: 'C-B',
			button: {
				textTransform: 'none',
			},
		},
		palette: {
			primary: {
				main: theme.primary_text,
			},
			secondary: {
				main: theme.primary_color,
			},
		},
		overrides: {
			MuiInput: {
				root: {
					color: theme.primary_text,
					fontSize: 16
				}
			},
			MuiInputLabel: {
				root: {
					fontSize: 16
				}
			}
		}
	});

	const contactSchema = yup.object().shape({
		email: yup
			.string()
			.email('Érvényes e-mailt adj meg!')
			.required('Kötelező mező!'),
		name: yup.string().required('Kötelező mező!'),
		message: yup.string().required('Kötelező mező!'),
	});

	return (
		<div className='col-lg-8 col-md-7 col-xs-12'>
			<Formik
				initialValues={{
					message: '',
					name: '',
					email: '',
				}}
				validationSchema={contactSchema}
				onSubmit={async (values, actions) => {
					setSending(true);
					MessageSender(values)
						.then(() => {
							if (isMounted) {
								setSending(false);
								setButtonText("elküldve");
								setButtonIcon(DoneRoundedIcon);
								setTimeout(() => {
									if (isMounted) {
										setButtonText("üzenet külése");
										setButtonIcon(SendRoundedIcon);
									}
								}, 1000);
							}
						})
						.catch(() => {
							if (isMounted) {
								setSending(false);
								setButtonColor(theme.error_color);
								setButtonText("próbáld újra");
								setButtonIcon(CloseRoundedIcon);
								setTimeout(() => {
									if (isMounted) {
										setButtonColor(theme.primary_color);
										setButtonText("üzenet külése");
										setButtonIcon(SendRoundedIcon);
									}
								}, 1000);
							}
						});
					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form onSubmit={props.handleSubmit}>
						<ThemeProvider theme={MuiTheme}>
							<ContactFieldContainer minHeight={'140px'}>
								<TextField
									variant='standard'
									id='contact-form-message'
									type='text'
									multiline
									rows={4}
									style={{ width: '100%' }}
									InputProps={{ disableUnderline: true }}
									label={<p>Miben segíthetünk?</p>}
									value={props.values.message}
									onChange={props.handleChange('message')}
									onBlur={() => props.setFieldTouched('message')}
									error={props.touched.message && !!props.errors.message}
									helperText={props.touched.message ? props.errors.message : ''}
								/>
							</ContactFieldContainer>
							<ContactFieldContainer minHeight={'85px'}>
								<TextField
									variant='standard'
									id='contact-form-name'
									type='text'
									style={{ width: '100%' }}
									InputProps={{ disableUnderline: true }}
									label={
										<p>
											Hogyan szólíthatunk? <span>*</span>
										</p>
									}
									value={props.values.name}
									onChange={props.handleChange('name')}
									onBlur={() => props.setFieldTouched('name')}
									error={props.touched.name && !!props.errors.name}
									helperText={props.touched.name ? props.errors.name : ''}
								/>
							</ContactFieldContainer>
							<ContactFieldAndButtonContainer>
								<ContactFieldContainer minHeight={'85px'}>
									<TextField
										variant='standard'
										id='contact-form-email'
										type='text'
										style={{ width: '100%' }}
										InputProps={{ disableUnderline: true }}
										label={
											<p>
												Email címed <span>*</span>
											</p>
										}
										value={props.values.email}
										onChange={props.handleChange('email')}
										onBlur={() => props.setFieldTouched('email')}
										error={props.touched.email && !!props.errors.email}
										helperText={props.touched.email ? props.errors.email : ''}
									/>
								</ContactFieldContainer>
								<SendingButton
									type='submit'
									color={buttonColor}
								>
									{!sending ? (
										<>
											{buttonText}&nbsp;
											<ButtonIcon />
										</>
									) : (
											<CircularProgress />
										)}
								</SendingButton>
							</ContactFieldAndButtonContainer>
						</ThemeProvider>
					</Form>
				)}
			</Formik>
		</div>
	);
};
export default ContactFormComp;
