import React from 'react';
import {
	Logo,
	NavbarWrapper,
	DottedMenuWrapper,
} from '../../styles/Navbar.style';
import { Link } from 'react-router-dom';
import ilineLogo from '../../../assets/images/iline.png';
import DottedMenu from './DottedMenu';
import NavbarOpened from './NavbarOpened';

const Navbar = () => {
	return (
		<>
			<NavbarWrapper>
				<Logo>
					<Link to="/">
						<img src={ilineLogo} alt="" />
					</Link>
				</Logo>
				<DottedMenuWrapper>
					<DottedMenu />
				</DottedMenuWrapper>
			</NavbarWrapper>
			<NavbarOpened />
		</>
	);
};

export default Navbar;
