import React from 'react';
import { FooterStyle } from '../styles/Site.style';
import ilineImg from '../../assets/images/iline.png';
import Divider from '../../assets/images/divider.png';

const Footer = () => {
	return (
		<footer style={{ margin: '60px 0' }}>
			<FooterStyle bg={Divider}>
				<div className="footer-divider">
					<img src={ilineImg} alt="itechline" />
				</div>
				<div className="copyright">© Minden jog fenntartva 2020.</div>
			</FooterStyle>
		</footer>
	);
};

export default Footer;
