import React, { useEffect, useState } from 'react';
import {
	PaginationContainer,
	OurWorksWrapper,
} from '../../styles/OurWorks.style';
import OurWorksCards from './ourWorksCards/OurWorksCards';
import ReactPaginate from 'react-paginate';
import ActiveLeftArrow from '../../../assets/images/left-arrow-active.png';
import ActiveRightArrow from '../../../assets/images/right-arrow-active.png';
import InactiveLeftArrow from '../../../assets/images/left-arrow-inactive.png';
import InactiveRightArrow from '../../../assets/images/right-arrow-inactive.png';

const OurWorksComp = (props) => {
	const { filteredReferences, active } = props;
	const [currentReferences, setCurrentReferences] = useState(
		filteredReferences.slice(0, 4)
	);
	const perPage = 4;
	const numOfPage = Math.ceil(filteredReferences.length / perPage);
	const [currentPage, setCurrentPage] = useState(0);

	const [leftArrowIsActive, setLeftArrowIsActive] = useState(false);
	const [rightArrowisActive, setRightArrowIsActive] = useState(true);

	useEffect(() => {
		setCurrentReferences(
			filteredReferences.slice(
				currentPage * perPage,
				currentPage * perPage + perPage
			)
		);
		//eslint-disable-next-line
	}, [filteredReferences]);

	useEffect(() => {
		if (currentPage === 0) {
			setLeftArrowIsActive(false);
		} else if (!leftArrowIsActive) {
			setLeftArrowIsActive(true);
		}
		if (currentPage === numOfPage - 1) {
			setRightArrowIsActive(false);
		} else if (!rightArrowisActive) {
			setRightArrowIsActive(true);
		}

		setCurrentReferences(
			filteredReferences.slice(
				currentPage * perPage,
				currentPage * perPage + perPage
			)
		);
		return () => scrollToHash('scrollHere');
		//eslint-disable-next-line
	}, [currentPage]);

	const scrollToHash = (hash) => {
		const element = document.getElementById(hash);
		if (!element) return;
		const yOffset = -40;
		const y =
			element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	};

	console.log(filteredReferences);

	return (
		<OurWorksWrapper className={active && 'active'}>
			{filteredReferences.length ? (
				<>
					<OurWorksCards works={currentReferences} />
					<PaginationContainer>
						<ReactPaginate
							pageCount={numOfPage}
							forcePage={currentPage}
							marginPagesDisplayed={0}
							pageRangeDisplayed={8}
							previousLabel={
								<img
									src={leftArrowIsActive ? ActiveLeftArrow : InactiveLeftArrow}
									alt=''
								/>
							}
							nextLabel={
								<img
									src={
										rightArrowisActive ? ActiveRightArrow : InactiveRightArrow
									}
									alt=''
								/>
							}
							activeClassName='activeSelector'
							pageClassName='pageSelector'
							nextClassName='nextPageButton'
							previousClassName='prevPageButton'
							onPageChange={({ selected }) => setCurrentPage(selected)}
						/>
					</PaginationContainer>
				</>
			) : null}
		</OurWorksWrapper>
	);
};

export default OurWorksComp;
