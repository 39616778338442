import React, { useEffect, useContext } from 'react';
import {
	LatestWorks,
	ServicesWrapper,
	ProudWrapper,
} from '../../styles/Home.style';
import { Container, SimpleTitle } from '../../styles/Site.style';
import LatestWorksComp from './latestWorks/LatestWorksComp';
import WelcomeTextComp from './WelcomeTextComp';
import ServicesComp from './ServicesComp';
import ProudComp from './ProudComp';
import Coffee from '../../global/Coffee';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg.png';

const Home = () => {
	const siteBgContext = useContext(SiteBgContext);
	useEffect(() => {
		window.scrollTo(0, 0);
		siteBgContext.setActiveBg(BG);
		//eslint-disable-next-line
	}, []);
	return (
		<Container>
			<SimpleTitle>
				<WelcomeTextComp />
			</SimpleTitle>
			<LatestWorks>
				<LatestWorksComp />
			</LatestWorks>
			<ServicesWrapper>
				<ServicesComp />
			</ServicesWrapper>
			<ProudWrapper>
				<ProudComp />
			</ProudWrapper>
			<Coffee />
		</Container>
	);
};

export default Home;
