import React, { useContext, useState, useEffect } from 'react';
import { ScrollUpButton } from '../styles/Site.style';
import UpIcon from '../../assets/images/to-top.png';
import NavbarContext from '../../context/navbar/navbarContext';

const ScrollUpButtonComp = () => {
	const { active } = useContext(NavbarContext);
	const [scroll_active, set_scroll_active] = useState(false);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		const runOnScroll = () =>
			window.scrollY > 600 ? set_scroll_active(true) : set_scroll_active(false);
		window.addEventListener('scroll', runOnScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', runOnScroll, { passive: true });
		};
	}, []);

	return (
		<ScrollUpButton
			size='60px'
			right='5%'
			bottom='10%'
			mobile='5%'
			icon={UpIcon}
			onClick={() => scrollToTop()}
			className={(!scroll_active || active) && 'hide'}
		/>
	);
};

export default ScrollUpButtonComp;
