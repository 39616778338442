import React, { useState } from 'react';
import WorksCardsHover from './WorksCardsHover';
import { WorksCardsImageCard } from "../../styles/Site.style";
/* import p1 from '../../../assets/images/placeholder-works-s.png';
import p2 from '../../../assets/images/placeholder-works-m.png';
import p3 from '../../../assets/images/placeholder-works-l.png'; */

const WorksCardItem = (props) => {
	const { size, workItem } = props;
	const [hovering, setHovering] = useState('');
	const onHover = (e) => {
		setHovering(e.target.dataset.work);
	};
	const onLeave = (e) => {
		setHovering('');
	};
	return (
		<div
			style={{ position: 'relative' }}
			onMouseEnter={onHover}
			onMouseLeave={onLeave}
		>
			{size === 's' ? (
				<div style={{ height: '453px' }}>
					<WorksCardsImageCard src={workItem.image} data-work='deac' alt={workItem.name} />
					<WorksCardsHover
						hovering={hovering === 'deac'}
						size={size}
						link={workItem.link}
						tags={workItem.tags}
						title={workItem.name}
					/>
				</div>
				) : size === 'm' ? (
				<div style={{ height: '580px' }}>
					<WorksCardsImageCard src={workItem.image} alt={workItem.name} />
					<WorksCardsHover
						hovering={hovering === 'deac'}
						size={size}
						link={workItem.link}
						tags={workItem.tags}
						title={workItem.name}
					/>
				</div>
				) : (
						<div style={{ height: '800px' }}>
							<WorksCardsImageCard src={workItem.image} alt={workItem.name} />
							<WorksCardsHover
								hovering={hovering === 'deac'}
								size={size}
								link={workItem.link}
								tags={workItem.tags}
								title={workItem.name}
							/>
						</div>
					)}
		</div>
	);
};

export default WorksCardItem;
