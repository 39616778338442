import React, { useState } from 'react';
import {
	AboutUsDropdownItemWrapper,
	AboutUsDropdownItemNumber,
	AboutUsDropdownItemText,
	AboutUsDropdownItemTextTitle,
	AboutUsDropdownItemTextDesc,
	AboutUsDropdownItemTextButton,
} from '../../styles/AboutUs.style';

const AboutUsDropdownItem = ({ num, lead, desc }) => {
	const [collapsed, set_collapsed] = useState(true);
	return (
		<AboutUsDropdownItemWrapper>
			<AboutUsDropdownItemNumber onClick={() => set_collapsed(!collapsed)}>
				{num}
			</AboutUsDropdownItemNumber>
			<AboutUsDropdownItemText>
				<AboutUsDropdownItemTextTitle
					collapsed={collapsed}
					onClick={() => set_collapsed(!collapsed)}
				>
					{lead}
				</AboutUsDropdownItemTextTitle>
				<AboutUsDropdownItemTextDesc collapsed={collapsed}>
					{desc}
				</AboutUsDropdownItemTextDesc>
				<AboutUsDropdownItemTextButton
					onClick={() => set_collapsed(!collapsed)}
				>
					{collapsed ? '+' : '-'}
				</AboutUsDropdownItemTextButton>
			</AboutUsDropdownItemText>
		</AboutUsDropdownItemWrapper>
	);
};

export default AboutUsDropdownItem;
