import styled, { css } from 'styled-components';
import * as theme from './Variables';
import { FancyButton } from './Buttons.style';

export const SitePattern = styled.div`
	background: url(${(props) => props.pattern});
	background-repeat: repeat;
	background-size: auto;
`;

export const SiteBG = styled.div`
	background: url(${(props) => props.bg});
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
	width: 100%;
	overflow: auto;
`;

export const Container = styled.main`
	max-width: 1400px;
	margin: auto;
	width: 100%;
	padding: 0 10px;
	@media (max-width: 1400px) {
		max-width: 1170px;
	}
`;

export const Spacer = styled.div`
	display: block;
	position: relative;
	height: ${(props) => props.height};
	${(props) =>
		props.mobile_none &&
		css`
			display: none;
		`}
	${(props) =>
		props.mobile_height &&
		css`
			height: ${props.mobile_height};
		`}
`;

export const UnderlinedText = styled.strong`
	font-family: C-B;
	position: relative;
	z-index: 0;
	&:after {
		background: url(${(props) => props.perpattern});
		z-index: -1;
		background-size: cover;
		height: 20px;
		width: 100%;
		content: '';
		position: absolute;
		bottom: 10px;
		left: 0;
		@media (max-width: 600px) {
			bottom: 5px;
		}
		@media (max-width: 400px) {
			bottom: 3px;
			height: 10px;
		}
	}
`;

export const Title = styled.div`
	padding: 30px 0 40px 0;
	font-size: 60px;
	display: flex;
	flex-wrap: wrap;
	p {
		font-size: 20px;
		color: ${theme.primary_text};
	}
	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		p {
			padding: 0;
			margin: 0;
			line-height: auto;
			font-size: 16px;
		}
	}
	@media (max-width: 600px) {
		font-size: 45px;
	}
	@media (max-width: 400px) {
		font-size: 25px;
	}
`;

export const SimpleTitle = styled.section`
	color: ${theme.primary_color};
	.lead {
		span {
			font-family: C-R;
		}
		p {
			margin: 0;
			font-family: C-B;
			strong {
				font-size: 100px;
				line-height: 1;
			}
			font-size: 70px;
			@media (max-width: 992px) {
				font-size: 40px;
				strong {
					font-size: 65px;
				}
			}
			@media (max-width: 500px) {
				font-size: 25px;
				strong {
					font-size: 35px;
				}
			}
		}
	}
	.sub {
		p {
			font-size: 30px;
			color: ${theme.primary_text};
			max-width: 60%;
			margin-top: 40px;
			line-height: 1.4;
			margin-bottom: 50px;
			@media (max-width: 600px) {
				font-size: 20px;
				max-width: 100%;
			}
			@media (max-width: 600px) {
				font-size: 16px;
			}
		}
	}
`;

export const WhiteContentBox = styled.div`
	background: white;
	border-radius: 50px;
	padding: 50px;
	box-shadow: 0px 60px 100px 0px rgba(61, 67, 79, 0.1);
	.coffee {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: ${theme.primary_text};
		& p {
			font-size: 30px;
		}
		& p span {
			font-family: C-B;
		}
		@media (max-width: 992px) {
			flex-direction: column;
			${FancyButton} {
				margin-bottom: 25px;
				margin-top: 55px;
			}
		}
	}
`;

export const FooterStyle = styled.div`
	.footer-divider {
		width: 100%;
		margin: 0 auto;
		height: 5px;
		background: url(${(props) => props.bg});
		background-repeat: no-repeat;
		background-size: contain;
		display: flex;
		align-items: center;
		background-position: center;
		img {
			margin: 0 auto;
		}
	}
	.copyright {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 60px 0 10px 0;
		font-size: 14px;
		color: ${theme.primary_text};
	}
`;

export const Tabs = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 40px 0;
	@media (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Tab = styled.p`
	margin: 0 30px;
	padding: 20px 10px;
	border-radius: 50px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	&.active {
		background: ${theme.blueish};
		color: white;
	}
`;

export const WorksCardsCols = styled.div`
	max-width: 49%;
	width: 100%;
	float: ${(props) => props.float};
	& > div {
		margin-bottom: 20px;
		border-radius: 30px;
		max-width: 100%;
		overflow: hidden;
	}
	img {
		max-width: 100%;
		width: 100%;
		height: 100%;
	}
	@media (max-width: 768px) {
		max-width: 100%;
	}
`;

export const WorksCardsImageCard = styled.div`
	background: url(${(props) => props.src});
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
`;

export const WorksCardsHoverStyle = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	transition: all 0.5s ease-in-out;
	background: rgba(87, 92, 100, 0.9);
	opacity: 0;
	padding: 50px;
	max-width: 100%;
	&:hover {
		opacity: 1;
	}
`;

export const WorksCardsHoverStyleCategory = styled.div`
	color: ${theme.blueish};
	font-size: 16px;
	font-family: C-B;
`;

export const WorksCardsHoverStyleTitle = styled.div`
	color: white;
	font-family: C-B;
	font-size: 30px;
	width: 70%;
	margin: 20px 0;
`;

export const WorksCardsHoverButtonWrapper = styled.div`
	margin-bottom: 50px;
	margin-top: auto;
`;

export const ScrollUpButton = styled.div`
	& {
		position: fixed;
		right: ${({ right }) => right};
		bottom: ${({ bottom }) => bottom};
		color: white;
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		width: ${({ size }) => size};
		height: ${({ size }) => size};
		border-radius: 50%;
		background: url(${({ icon }) => icon}),
			linear-gradient(${theme.deep_purple} 20%, ${theme.blueish} 100%);
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto;
		visibility: visible;
		opacity: 1;
		transition: all 0.25s ease-in;
		@media (max-width: 768px) {
			bottom: ${({ mobile }) => mobile};
		}
	}
	&: after {
		position: absolute;
		content: '';
		top: -2px;
		right: -2px;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: ${theme.deep_purple};
	}

	&.hide {
		visibility: hidden;
		opacity: 0;
		transform: scale(0);
	}
`;
