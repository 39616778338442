import React from 'react';

const AboutUsTitle = () => {
	return (
		<>
			<div className='lead'>
				<p>
					<strong>Online termékek megvalósításához</strong>
				</p>
				<p>kínálunk szolgáltatásokat Debrecenből.</p>
			</div>
			<div className='sub'>
				<p>
					A siker kulcsa a tökéletes felhasználói élmény. Aprólékos elemzést
					végzünk és jelentős figyelmet szentelünk a tervezésre. Személyes
					találkozóink közben a fejlesztési folyamatokat összhangba hozzuk
					céljaiddal, hogy a lehető leghatékonyabb végeredményt kapd. Minőségi{' '}
					{'<kódokat>'} kínálunk, ami a jövőre nézve mindig könnyű
					továbbfejlesztési potenciált rejt magában.
				</p>
			</div>
		</>
	);
};

export default AboutUsTitle;
