import React from 'react';
import { Title, UnderlinedText } from '../../styles/Site.style';
import PerPattern from '../../../assets/images/perpattern.png';

const MainTitleComp = () => {
	return (
		<>
			<Title>
				Lépjünk&nbsp;
				<UnderlinedText perpattern={PerPattern}>kapcsolatba</UnderlinedText>
			</Title>
			<div>
				<p>
					Valamelyik munkánk netán felkeltette az <span>érdeklődésedet</span>?
					Vagy éppen szükséged lenne egy új&nbsp; <span>webes megoldásra</span>?
					Írj nekünk egy levelet itt vagy a lenti e-mail címre, 24 órán belül
					válaszolni fogunk.
				</p>
			</div>
		</>
	);
};

export default MainTitleComp;
