import styled from 'styled-components';
import * as theme from './Variables';


export const ContactTitle = styled.div`
  color: ${theme.primary_color};
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
      max-width: 860px;
  }
  p {
    color: ${theme.primary_text};
    font-size: 22px;
    font-family: C-R;
    line-height: 1.5;

    span {
      font-family: C-B;
    }
  }
`;

export const ContactInfoParagraph = styled.p`
  font-family: C-R;
  font-size: 18px;
  color: ${theme.primary_color};
  span {
    font-family: C-B;
  }
`;

export const ContactInfoTitle = styled.p`
  font-family: C-B;
  font-size: 30px;
  font-weight: bold;
  color: ${theme.primary_color};
  margin-bottom: 1.5rem !important;
`;

export const ContactsContainer = styled.section`
  padding: 0 50px;
  @media screen and (max-width: 991px) {
    padding: 0 25px;
  }
  p {
    margin-bottom: 0.5rem;
    color: ${theme.primary_text};
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    color: ${theme.primary_color};
  }
`;

export const SendingButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  color: white;
  border: 0;
  outline: none;
  height: 60px;
  margin: 12.5px 20px 37.5px 20px;
  border-radius: 30px;
  font-family: C-B;

  -webkit-transition: background-color .25s linear;
  -ms-transition: background-color .25s linear;
  transition: background-color .25s linear;

  &:active, &:focus {
    outline: none;
  }

  @media screen and (max-width: 991px) {
    margin: 0;
    display: block;
    flex: none;
    width: 60%;
  }
  
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const ContactFieldContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: ${(props) => props.minHeight};
  margin-bottom: 25px;
  padding: 5px 0 0 25px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 15px 40px 0px rgba(61, 67, 79, 0.08);
  -moz-box-shadow: 0px 15px 40px 0px rgba(61, 67, 79, 0.08);
  box-shadow: 0px 15px 40px 0px rgba(61, 67, 79, 0.08);
  width: 100%;

  @media screen and (max-width: 991px) {
    display: block;
    flex: none;
  }
  
  p {
    color: ${theme.input_label_color}
  }

  span {
    color: ${theme.error_color}
  }
`;

export const ContactFieldAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;