import React, { useEffect, useContext } from 'react';
import { Container, Spacer } from '../../styles/Site.style';
import { ContactTitle, ContactsContainer } from '../../styles/ContactUs.style';
import ContactTitleComp from './ContactTitleComp';
import ContactFormComp from './ContactFormComp';
import ContactInfoComp from './ContactInfoComp';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg-kapcsolat-top.png';

const ContactUS = () => {
	const siteBgContext = useContext(SiteBgContext);
	useEffect(() => {
		window.scrollTo(0, 0);
		siteBgContext.setActiveBg(BG);
		//eslint-disable-next-line
	}, []);
	return (
		<Container>
			<ContactTitle>
				<ContactTitleComp />
			</ContactTitle>
			<Spacer height='180px' mobile_height='60px' />
			<ContactsContainer>
				<div className='row'>
					<ContactInfoComp />
					<ContactFormComp />
				</div>
			</ContactsContainer>
			<Spacer height='180px' mobile_height='60px' />
		</Container>
	);
};

export default ContactUS;
