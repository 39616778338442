import styled from 'styled-components';
import * as theme from './Variables.js';

export const LatestWorks = styled.section`
	max-width: 100%;
	position: relative;
	overflow: auto;
`;

export const ServicesWrapper = styled.section`
	position: relative;
	display: block;
	padding: 0 15px;
`;

export const ServiceBoxTitle = styled.p`
	height: 70px;
	display: flex;
	font-family: C-B;
	font-size: 30px;
	margin: 20px 0;
	line-height: 30px;
`;

export const ServiceBoxDesc = styled.p`
	color: ${theme.primary_text};
	font-size: 16px;
	margin-bottom: 30px;
	line-height: 1.4;
`;

export const ProudWrapper = styled.section`
	width: 100%;
	text-align: center;
	margin-top: 90px;
	.logos {
		padding: 50px 0;
	}
	p:not(:first-of-type) {
		color: ${theme.primary_text};
	}
	p:first-of-type {
		font-size: 60px;
		@media (max-width: 992px) {
			font-size: 40px;
		}
		@media (max-width: 600px) {
			font-size: 35px;
		}
		@media (max-width: 400px) {
			font-size: 30px;
		}
	}
	.proud-item,
	.proud-item a {
		img {
			max-height: 100%;
		}
		outline: none;
		border: none;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		filter: grayscale(1);
		transition: all 0.25s linear;
		&:hover {
			filter: grayscale(0);
		}
	}
	@media (max-width: 992px) {
		.proud-item {
			margin: 30px 0;
		}

		.proud-item,
		.proud-item a {
			img {
				max-height: 70%;
			}
		}
	}
`;
