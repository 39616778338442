import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavbarOpenedWrapper } from '../../styles/Navbar.style';
import NavbarContext from '../../../context/navbar/navbarContext';
import menubg from '../../../assets/images/menubg.png';
import divider from '../../../assets/images/divider.png';
import iline from '../../../assets/images/iline.png';

const NavbarOpened = () => {
	const navbarContext = useContext(NavbarContext);
	const { active, toggleActive } = navbarContext;
	useEffect(() => {
		var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

		function preventDefault(e) {
			e.preventDefault();
		}

		function preventDefaultForScrollKeys(e) {
			if (keys[e.keyCode]) {
				preventDefault(e);
				return false;
			}
		}

		// modern Chrome requires { passive: false } when adding event
		var supportsPassive = false;
		try {
			window.addEventListener(
				'test',
				null,
				Object.defineProperty({}, 'passive', {
					get: function () {
						return (supportsPassive = true);
					},
				})
			);
		} catch (e) {}

		var wheelOpt = supportsPassive ? { passive: false } : false;
		var wheelEvent =
			'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

		// call this to Disable
		function disableScroll() {
			window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
			window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
			window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
			window.addEventListener('keydown', preventDefaultForScrollKeys, false);
		}

		// call this to Enable
		function enableScroll() {
			window.removeEventListener('DOMMouseScroll', preventDefault, false);
			window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
			window.removeEventListener('touchmove', preventDefault, wheelOpt);
			window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
		}
		if (active) {
			window.scrollTo(0, 0);
			const CURSOR = document.querySelector('#cursorBlob');
			const LINKS = document.querySelectorAll('.nav__link');

			const setCursorPos = (e) => {
				const { pageX: posX, pageY: posY } = e;
				CURSOR.style.top = `${posY - CURSOR.offsetHeight / 2}px`;
				CURSOR.style.left = `${posX - CURSOR.offsetWidth / 2}px`;
			};
			document.addEventListener('mousemove', setCursorPos);

			const setCursorHover = () => (CURSOR.style.transform = 'scale(2.5)');
			const removeCursorHover = () => (CURSOR.style.transform = '');

			LINKS.forEach((link) =>
				link.addEventListener('mouseover', setCursorHover)
			);
			LINKS.forEach((link) =>
				link.addEventListener('mouseleave', removeCursorHover)
			);
			disableScroll();
		} else {
			const CURSOR = document.querySelector('#cursorBlob');
			const LINKS = document.querySelectorAll('.nav__link');
			const setCursorPos = (e) => {
				const { pageX: posX, pageY: posY } = e;
				CURSOR.style.top = `${posY - CURSOR.offsetHeight / 2}px`;
				CURSOR.style.left = `${posX - CURSOR.offsetWidth / 2}px`;
			};
			document.removeEventListener('mousemove', setCursorPos);

			const setCursorHover = () => (CURSOR.style.transform = 'scale(2.5)');
			const removeCursorHover = () => (CURSOR.style.transform = '');

			LINKS.forEach((link) =>
				link.removeEventListener('mouseover', setCursorHover)
			);
			LINKS.forEach((link) =>
				link.removeEventListener('mouseleave', removeCursorHover)
			);
		}
		return () => {
			enableScroll();
		};
	}, [active]);

	return (
		<NavbarOpenedWrapper menubg={menubg} divider={divider}>
			<div id={`${active && 'cursorBlob'}`}></div>
			<div className={`wrap ${active && 'active'}`}>
				<ul className='nav'>
					<li className='nav__link'>
						<Link to='/' onClick={toggleActive}>
							Kezdőlap
						</Link>
					</li>
					<li className='nav__link'>
						<Link to='/rolunk' onClick={toggleActive}>
							Rólunk
						</Link>
					</li>
					<li className='nav__link'>
						<Link to='/szolgaltatasok' onClick={toggleActive}>
							Szolgáltatások
						</Link>
					</li>
					<li className='nav__link'>
						<Link to='/munkaink' onClick={toggleActive}>
							Munkáink
						</Link>
					</li>
					<li className='nav__link'>
						<Link to='/kapcsolat' onClick={toggleActive}>
							Kapcsolat
						</Link>
					</li>
				</ul>
				<div className='divider'>
					<img src={iline} alt='' />
				</div>
			</div>
		</NavbarOpenedWrapper>
	);
};

export default NavbarOpened;
