import React from "react";
import { Spacer } from '../../styles/Site.style';
import { ContactInfoParagraph, ContactInfoTitle } from '../../styles/ContactUs.style';


const ContactInfoComp = () => {
    return (
        <div className="col-lg-4 col-md-5 col-xs-12">
            <div>
                <ContactInfoTitle>Itt dolgozunk</ContactInfoTitle>
                <ContactInfoParagraph><span>Debrecen</span></ContactInfoParagraph>
                <ContactInfoParagraph>Bercsényi 51.</ContactInfoParagraph>
            </div>
            <Spacer height="80px" />
            <div>
                <ContactInfoTitle>Elérhetőségeink</ContactInfoTitle>
                <ContactInfoParagraph><span>Telefon:</span> +36 30 442 99 57</ContactInfoParagraph>
                <ContactInfoParagraph><span>Email:</span> hello@itechline.hu</ContactInfoParagraph>
            </div>
            <Spacer height="80px" />
        </div>
    );
}
export default ContactInfoComp;