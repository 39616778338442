import React from 'react';
import { Link } from 'react-router-dom';
import { WhiteContentBox } from '../styles/Site.style';
import { FancyButton } from '../styles/Buttons.style';
import CoffeeImg from '../../assets/images/coffee.png';

const Coffee = () => {
	return (
		<WhiteContentBox>
			<div className="coffee">
				<div>
					<p>
						Szükséged lenne egy <span>új webes megoldásra?</span>
					</p>
					<span>
						Vedd fel velünk a kapcsolatot és egy <img src={CoffeeImg} alt="" />{' '}
						mellett megbeszéljük.
					</span>
				</div>
				<FancyButton>
					<Link to="/kapcsolat">írj nekünk {'>'}</Link>
				</FancyButton>
			</div>
		</WhiteContentBox>
	);
};

export default Coffee;
