import styled from 'styled-components';
import * as theme from './Variables.js';

export const NavbarWrapper = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1400px;
	margin: auto;
	height: 100px;
	position: relative;
	padding: 0 15px;
	margin-bottom: 150px;
	margin-top: 50px;
	& > div:last-child {
		position: relative;
		right: 30px;
	}
	div:last-child,
	div:last-child > * {
		z-index: 4;
	}
	@media (max-width: 1400px) {
		max-width: 1170px;
	}
	@media (max-width: 1170px) {
		max-width: 100%;
		padding: 15px;
	}
`;

export const Logo = styled.div`
	a {
		display: block;
		margin: 0;
		padding: 0;
		text-decoration: none;
		img {
			width: 100%;
			display: block;
			max-width: 175px;
			height: auto;
		}
	}
`;

export const DottedMenuWrapper = styled.div`
	text-decoration: none;
	color: #6b6599;
	cursor: pointer;
	.menu {
		position: relative;
		display: block;
		width: 40px;
		height: 40px;
		margin: 0 auto 0 auto;
	}
	.menu .pix {
		position: absolute;
		width: 8px;
		height: 8px;
		background-color: #9e107e;
		border-radius: 100%;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}
	.menu .pix:nth-of-type(1) {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}
	.menu .pix:nth-of-type(2) {
		-webkit-transform: translate(16px, 0px);
		transform: translate(16px, 0px);
	}
	.menu .pix:nth-of-type(3) {
		-webkit-transform: translate(32px, 0px);
		transform: translate(32px, 0px);
	}
	.menu .pix:nth-of-type(4) {
		-webkit-transform: translate(0px, 16px);
		transform: translate(0px, 16px);
	}
	.menu .pix:nth-of-type(5) {
		-webkit-transform: translate(16px, 16px);
		transform: translate(16px, 16px);
	}
	.menu .pix:nth-of-type(6) {
		-webkit-transform: translate(32px, 16px);
		transform: translate(32px, 16px);
	}
	.menu .pix:nth-of-type(7) {
		-webkit-transform: translate(0px, 32px);
		transform: translate(0px, 32px);
	}
	.menu .pix:nth-of-type(8) {
		-webkit-transform: translate(16px, 32px);
		transform: translate(16px, 32px);
	}
	.menu .pix:nth-of-type(9) {
		-webkit-transform: translate(32px, 32px);
		transform: translate(32px, 32px);
	}
	.menu:hover .pix {
		background-color: #b90190;
	}
	.menu:hover .pix:nth-of-type(1) {
		-webkit-transform: translate(-8px, -8px);
		transform: translate(-8px, -8px);
	}
	.menu:hover .pix:nth-of-type(3) {
		-webkit-transform: translate(40px, -8px);
		transform: translate(40px, -8px);
	}
	.menu:hover .pix:nth-of-type(7) {
		-webkit-transform: translate(-8px, 40px);
		transform: translate(-8px, 40px);
	}
	.menu:hover .pix:nth-of-type(9) {
		-webkit-transform: translate(40px, 40px);
		transform: translate(40px, 40px);
	}
	.menu.active .pix:nth-of-type(1) {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	.menu.active .pix:nth-of-type(2) {
		-webkit-transform: translate(8px, 8px);
		transform: translate(8px, 8px);
	}
	.menu.active .pix:nth-of-type(3) {
		-webkit-transform: translate(32px, 0);
		transform: translate(32px, 0);
	}
	.menu.active .pix:nth-of-type(4) {
		-webkit-transform: translate(8px, 24px);
		transform: translate(8px, 24px);
	}
	.menu.active .pix:nth-of-type(6) {
		-webkit-transform: translate(24px, 8px);
		transform: translate(24px, 8px);
	}
	.menu.active .pix:nth-of-type(7) {
		-webkit-transform: translate(0, 32px);
		transform: translate(0, 32px);
	}
	.menu.active .pix:nth-of-type(8) {
		-webkit-transform: translate(24px, 24px);
		transform: translate(24px, 24px);
	}
	.menu.active .pix:nth-of-type(9) {
		-webkit-transform: translate(32px, 32px);
		transform: translate(32px, 32px);
	}
	.menu.active:hover .pix:nth-of-type(1) {
		-webkit-transform: translate(-8px, -8px);
		transform: translate(-8px, -8px);
	}
	.menu.active:hover .pix:nth-of-type(2) {
		-webkit-transform: translate(4px, 4px);
		transform: translate(4px, 4px);
	}
	.menu.active:hover .pix:nth-of-type(3) {
		-webkit-transform: translate(40px, -8px);
		transform: translate(40px, -8px);
	}
	.menu.active:hover .pix:nth-of-type(4) {
		-webkit-transform: translate(4px, 28px);
		transform: translate(4px, 28px);
	}
	.menu.active:hover .pix:nth-of-type(6) {
		-webkit-transform: translate(28px, 4px);
		transform: translate(28px, 4px);
	}
	.menu.active:hover .pix:nth-of-type(7) {
		-webkit-transform: translate(-8px, 40px);
		transform: translate(-8px, 40px);
	}
	.menu.active:hover .pix:nth-of-type(8) {
		-webkit-transform: translate(28px, 28px);
		transform: translate(28px, 28px);
	}
	.menu.active:hover .pix:nth-of-type(9) {
		-webkit-transform: translate(40px, 40px);
		transform: translate(40px, 40px);
	}
	@media (max-width: 1200px) {
		.menu:hover .pix:nth-of-type(1) {
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
		}
		.menu:hover .pix:nth-of-type(3) {
			-webkit-transform: translate(32px, 0px);
			transform: translate(32px, 0px);
		}
		.menu:hover .pix:nth-of-type(7) {
			-webkit-transform: translate(0, 32px);
			transform: translate(0, 32px);
		}
		.menu:hover .pix:nth-of-type(9) {
			-webkit-transform: translate(32px, 32px);
			transform: translate(32px, 32px);
		}
	}
`;

export const NavbarOpenedWrapper = styled.div`
	overflow: hidden;
	position: absolute;
	visibility: hidden;
	top: 0;
	.divider {
		width: 80%;
		margin: 0 auto;
		position: absolute;
		height: 5px;
		background: url(${(props) => props.divider});
		background-repeat: no-repeat;
		background-size: contain;
		bottom: 50px;
		display: flex;
		align-items: center;
		bottom: 100px;
		img {
			margin: 0 auto;
		}
	}
	#cursorBlob {
		width: 50px;
		height: 50px;
		visibility: visible;
		background: linear-gradient(
			120deg,
			#ff1744,
			#e040fb,
			#2979ff,
			#00e5ff,
			#76ff03
		);
		background-size: 1600% 1600%;
		position: fixed;
		mix-blend-mode: difference;
		pointer-events: none;
		z-index: 4;
		transition: 0.15s linear;
		animation: blobRadius 5s ease infinite, blobBackground 15s ease infinite;
	}

	@keyframes blobRadius {
		0%,
		100% {
			border-radius: 43% 77% 80% 40% / 40% 40% 80% 80%;
		}
		20% {
			border-radius: 47% 73% 61% 59% / 47% 75% 45% 73%;
		}
		40% {
			border-radius: 46% 74% 74% 46% / 74% 58% 62% 46%;
		}
		60% {
			border-radius: 47% 73% 61% 59% / 40% 40% 80% 80%;
		}
		80% {
			border-radius: 50% 70% 52% 68% / 51% 61% 59% 69%;
		}
	}
	@keyframes blobBackground {
		0%,
		100% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
	}
	.wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;
		background: url(${(props) => props.menubg});
		transition: all 0.5s ease-in-out;
		position: fixed;
		transform: translateX(100%);
		z-index: -1;
		visibility: hidden;
		&.active {
			z-index: 3;
			visibility: visible;
			transform: translateX(0%);
			transition: all 0.5s ease-in-out;
		}
	}

	.nav {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		&__link,
		&__link a {
			text-decoration: none;
			color: ${theme.primary_color};
			list-style: none;
			cursor: pointer;
			font-size: 60px;
			transition: 0.25s ease;
			text-transform: lowercase;
			font-family: C-B;
			text-align: center;
			&:not(:last-child) {
				margin-bottom: 50px;
			}
			@media (max-width: 992px) {
				font-size: 50px;
			}
			@media (max-width: 768px) {
				font-size: 40px;
			}
			@media (max-width: 500px) {
				font-size: 30px;
			}
		}
	}
`;
