import React from 'react';
import ProudItem from './ProudItem';
import SuzukiLogo from '../../../assets/images/suzuki.png';
import DvscLogo from '../../../assets/images/dvsc.png';
import DeacLogo from '../../../assets/images/deac.png';
import UnifitLogo from '../../../assets/images/Unifit-logo-mobile-itechline.png';

const ProudComp = () => {
	return (
		<>
			<p>
				Kiemelt <strong>partnereink</strong>
			</p>
			<p>Köszönjük a bizalmat.</p>
			<div className='row logos'>
				<ProudItem
					img={SuzukiLogo}
					link='https://motor.suzuki.hu'
					alt='Suzuki Zrt.'
				/>
				<ProudItem img={DvscLogo} link='https://dvsc.hu' alt='DVSC' />
				<ProudItem img={DeacLogo} link='https://deac.hu' alt='DEAC' />
				<ProudItem
					img={UnifitLogo}
					link='https://stadionunifit.hu'
					alt='UniFit'
				/>
			</div>
		</>
	);
};

export default ProudComp;
