import React from 'react';
import { Link } from 'react-router-dom';
import {
	Title,
	UnderlinedText,
	WhiteContentBox,
} from '../../styles/Site.style';
import { ServiceBoxTitle, ServiceBoxDesc } from '../../styles/Home.style';
import { FancyButton } from '../../styles/Buttons.style';
import PerPattern from '../../../assets/images/perpattern.png';
import WebserviceImg from '../../../assets/images/services/service-web.png';
import GraphicsImg from '../../../assets/images/services/service-graphics.png';
import MarketingImg from '../../../assets/images/services/service-marketing.png';

const ServicesComp = () => {
	return (
		<>
			<Title>
				Igyekszünk mindig a&nbsp;
				<div className='mobile-only servicemobile'>
					<UnderlinedText perpattern={PerPattern}>tökéletes</UnderlinedText>
					<UnderlinedText perpattern={PerPattern}>megoldást</UnderlinedText>
				</div>
				<div className='mobile-none'>
					<UnderlinedText perpattern={PerPattern}>
						tökéletes megoldást
					</UnderlinedText>
				</div>
				nyújtani ügyfeleinknek
			</Title>
			<p>
				Soha nem maradunk le, naprakészen tartjuk magunkat a mindennapi
				trendekből.
			</p>
			<WhiteContentBox className='row'>
				<div className='col-lg-4 mt-5 mb-5'>
					<div style={{ height: '100px' }}>
						<img src={WebserviceImg} alt='' />
					</div>
					<ServiceBoxTitle>Web és Mobil App fejlesztés</ServiceBoxTitle>
					<ServiceBoxDesc>
						Több éves honlapkészítési tapasztalattal a hátunk mögött
						garantáljuk, hogy jól megtervezett, átgondolt weboldalt készítünk
						majd számodra is! &nbsp;
						<br />
						&nbsp;
						<br />
						&nbsp;
					</ServiceBoxDesc>
					<FancyButton>
						<Link to={{ pathname: '/szolgaltatasok', query: { tab: 'web' } }}>
							{'bővebben >'}
						</Link>
					</FancyButton>
				</div>
				<div className='col-lg-4 mt-5 mb-5'>
					<div style={{ height: '100px' }}>
						<img src={GraphicsImg} alt='' />
					</div>
					<ServiceBoxTitle>Grafika</ServiceBoxTitle>
					<ServiceBoxDesc>
						A logó maga a cég. A brand elengedhetetlen része, amellyel
						felismerik vállalkozásunkat. Személyreszabott kialakítása
						kulcskérdés, egyben egyike a legnehezebb feladatoknak, hisz
						megalkotása után ezzel azonosítanak majd ügyfeleid.
					</ServiceBoxDesc>
					<FancyButton>
						<Link
							to={{ pathname: '/szolgaltatasok', query: { tab: 'grafika' } }}
						>
							bővebben {'>'}
						</Link>
					</FancyButton>
				</div>
				<div className='col-lg-4 mt-5 mb-5'>
					<div style={{ height: '100px' }}>
						<img src={MarketingImg} alt='' />
					</div>
					<ServiceBoxTitle>Marketing</ServiceBoxTitle>
					<ServiceBoxDesc>
						Segítünk vállalkozásod social platformjait elindítani és
						üzemeltetni, legyen szó akár Facebook oldalról Google hálózatról.
						&nbsp;
						<br />
						&nbsp;
						<br />
						&nbsp;
						<br />
						&nbsp;
					</ServiceBoxDesc>
					<FancyButton>
						<Link
							to={{ pathname: '/szolgaltatasok', query: { tab: 'marketing' } }}
						>
							{'bővebben >'}
						</Link>
					</FancyButton>
				</div>
			</WhiteContentBox>
		</>
	);
};

export default ServicesComp;
