import { SET_SITE_BG } from '../types';

export default (state, action) => {
	switch (action.type) {
		case SET_SITE_BG:
			return {
				...state,
				site_bg: action.payload,
			};
		default:
			return state;
	}
};
