import Vermix from '../../../assets/images/ourWorks/bg-mainpage-works-big-vermix.jpg';
import Tgdental from '../../../assets/images/ourWorks/bg-mainpage-works-small-tgdental.jpg';
import Suzuki_web from '../../../assets/images/ourWorks/bg-mainpage-works-big-suzuki.jpg';
import Dvsc from '../../../assets/images/ourWorks/bg-mainpage-works-big-dvsc.jpg';
import Unifit from '../../../assets/images/ourWorks/bg-mainpage-works-big-unifit.jpg';
import Dka from '../../../assets/images/ourWorks/bg-mainpage-works-small-dka.jpg';
import Deac_v2 from '../../../assets/images/ourWorks/bg-mainpage-works-small-deac-2020.jpg';
import Deac_v1 from '../../../assets/images/ourWorks/bg-mainpage-works-small-deac-2018.jpg';
import Suzuki_2020 from '../../../assets/images/ourWorks/bg-mainpage-works-small-grafika-flup-katalogus-2020.jpg';
import Suzuki_2019 from '../../../assets/images/ourWorks/bg-mainpage-works-big-grafika-flup-katalogus-2019.jpg';
import Suzuki_2018 from '../../../assets/images/ourWorks/bg-mainpage-works-small-grafika-flup-katalogus-2018.jpg';

export default [
	{
		name: 'Vermix Hungary Kft.',
		year: 2019,
		description: '',
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend', 'social média'],
		image: Vermix,
		link: 'vermix',
		order: 7,
	},
	{
		name: 'UniFit Fitness & Gym Center',
		description: '',
		year: 2019,
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend', 'social média'],
		image: Unifit,
		link: 'unifit',
		order: 9,
	},
	{
		name: 'TG Dental fogorvosi rendelő',
		description: '',
		year: 2018,
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend'],
		image: Tgdental,
		link: 'tgdental',
		order: 8,
	},
	{
		name: 'Magyar Suzuki Zrt. motor divíziója',
		description: '',
		year: 2020,
		category: 'weboldalak',
		tags: [
			'Arculattervezés',
			'frontend',
			'backend',
			'szerver üzemeltetés',
			'karbantartás',
		],
		image: Suzuki_web,
		link: 'suzuki-web',
		order: 1,
	},
	{
		name: 'DVSC Zrt.',
		description: '',
		year: 2020,
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend'],
		image: Dvsc,
		link: 'dvsc',
		order: 2,
	},
	{
		name: 'Debreceni Kosárlabda Akadémia',
		description: '',
		year: 2020,
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend'],
		image: Dka,
		link: 'deac-ka',
		order: 3,
	},
	{
		name: 'Debreceni Egyetem Atétikai Club 2.0',
		year: 2020,
		description: '',
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend'],
		image: Deac_v2,
		link: 'deac-v2',
		order: 5,
	},
	{
		name: 'Debreceni Egyetem Atétikai Club 1.0',
		description: '',
		category: 'weboldalak',
		tags: ['Arculattervezés', 'frontend', 'backend'],
		year: 2018,
		image: Deac_v1,
		link: 'deac-v1',
		order: 11,
	},
	{
		name: 'SUZUKI Éves katalógus (2020)',
		description: '',
		category: 'grafikák',
		tags: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logisztika',
		],
		year: 2020,
		image: Suzuki_2020,
		link: 'suzuki-katalogus-2020',
		order: 4,
	},
	{
		name: 'SUZUKI Éves katalógus (2019)',
		description: '',
		category: 'grafikák',
		tags: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logisztika',
		],
		year: 2019,
		image: Suzuki_2019,
		link: 'suzuki-katalogus-2019',
		order: 6,
	},
	{
		name: 'SUZUKI Éves katalógus (2018)',
		description: '',
		category: 'grafikák',
		tags: [
			'Grafikai tervezés',
			'nyomdai kivitelezés',
			'szállítmányozás/logisztika',
		],
		year: 2018,
		image: Suzuki_2018,
		link: 'suzuki-katalogus-2018',
		order: 10,
	},
];
