import React from 'react';

const ProudItem = ({ link, img, alt }) => {
	return (
		<div className="col-lg-3 col-md-6 col-xs-12 proud-item">
			<a href={link} target="_blank" rel="noopener noreferrer">
				<img src={img} alt={alt} />
			</a>
		</div>
	);
};

export default ProudItem;
