import React, { useContext } from 'react';
import NavbarContext from '../../../context/navbar/navbarContext';

const DottedMenu = () => {
	const navbarContext = useContext(NavbarContext);
	return (
		<div
			className={`menu ${navbarContext.active && 'active'}`}
			onClick={() => navbarContext.toggleActive()}
		>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
			<div className='pix'></div>
		</div>
	);
};

export default DottedMenu;
