import React from 'react';
import { ServiceHoverWrapper } from '../../styles/Services.style';
import CloseImg from '../../../assets/images/services/service-close.png';
import ServicesHoverFooter from './ServicesHoverFooter';
import HTMLParser from 'html-react-parser';

const ServicesHover = ({ hover, closeClick, data, alt }) => {
	return (
		<ServiceHoverWrapper className={hover && `active`}>
			<div className='service__close' onClick={() => closeClick()}>
				<img src={CloseImg} alt='close' />
			</div>
			<div className='service__title'>
				<span className='service__number'>{data.number}</span>
				<p>{data.title}</p>
			</div>
			<div className={`service__desc regular-text ${alt && 'align-left'}`}>
				{HTMLParser(data.hover)}
			</div>
			<ServicesHoverFooter {...data.style} alt={alt} />
		</ServiceHoverWrapper>
	);
};

export default ServicesHover;
