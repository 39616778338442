import styled, { css } from 'styled-components';
import * as theme from './Variables.js';

export const FancyButton = styled.div`
	a,
	button {
		background: transparent;
		padding: 10px 20px;
		border: 3px solid ${theme.primary_color};
		border-radius: 50px;
		display: inline;
		text-decoration: none;
		color: ${theme.primary_color} !important;
		box-shadow: 3px 3px 0px ${theme.deep_purple};
		position: relative;
		z-index: 1;
		transition: all 0.25s ease-in-out;
		outline: none;
		white-space: nowrap;
		${(props) =>
			props.float &&
			css`
				float: ${props.float};
			`}
		${(props) =>
			props.margin &&
			css`
				margin: ${props.margin};
			`}
			margin: 3px;
		&:after {
			transition: all 0.25s ease-in-out;
			display: block;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50px;
			top: 3px;
			z-index: 0;
			box-shadow: -3px -3px 0px ${theme.blueish};
			left: 3px;
		}
		&:hover {
			box-shadow: -3px -3px 0px ${theme.deep_purple};
			&:after {
				box-shadow: 3px 3px 0px ${theme.blueish};
				top: -3px;
				left: -3px;
			}
		}
	}
`;

export const SimpleBorderedButton = styled.div`
	a {
		padding: 10px 20px;
		border: 3px solid ${theme.blueish};
		border-radius: 50px;
		display: inline;
		text-decoration: none;
		color: ${theme.blueish};
		font-size: 16px;

		&:visited,
		&:hover {
			color: ${theme.blueish};
		}
	}
`;
