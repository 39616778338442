import React from 'react';
import { Container, SimpleTitle } from '../../styles/Site.style';
import {
	OurWorksSubsImages,
	OurWorksSubsText,
} from '../../styles/OurWorksSub.style';
import Coffee from '../../global/Coffee';
import HTMLParser from 'html-react-parser';

const OurWoksSubsLayout = ({ data }) => {
	return (
		<Container>
			<SimpleTitle>
				<div className='lead'>
					<p>
						<strong>
							{data.title} <span>{data.title_type}</span>
						</strong>
					</p>
				</div>
			</SimpleTitle>
			<OurWorksSubsImages type={data.type}>
				{/*should remove object value stuff when api call comes, it's because I refer the image as object in data*/}
				<div className='owsi__main'>
					<img src={Object.values(data.main_img)[0]} alt='' />
				</div>
				<div className='owsi__sub_first'>
					<img src={Object.values(data.sub_img_first)[0]} alt='' />
				</div>
				{data.type === 'web' && (
					<div className='owsi__sub_second'>
						<img src={Object.values(data.sub_img_second)[0]} alt='' />
					</div>
				)}
			</OurWorksSubsImages>
			<OurWorksSubsText>
				<div className='owsi__wrapper'>
					<div className='owsi__wrapper--right'>
						<div className='owsi__lead'>Áttekintés</div>
						<div className='owsi__sub_lead'>{data.lead}</div>
						<div className='owsi__text'>
							<div className='owsi__text__simple'>{HTMLParser(data.text)}</div>
						</div>
					</div>
					<div className='owsi__text__overview owsi__wrapper--left'>
						<div className='owsi__text__overview__lead'>Részletek</div>
						<div className='owsi__text__overview__flow'>
							<div className='owsi__text__overview__flow__first'>
								<p>Megrendelő</p>
								<span>{HTMLParser(data.overview[0])}</span>
							</div>
							<div className='owsi__text__overview__flow__second'>
								<p>Elkészítés éve</p>
								<span>{HTMLParser(data.overview[1])}</span>
							</div>
							<div className='owsi__text__overview__flow__third'>
								<p>Megvalósítás</p>
								<span>{HTMLParser(data.overview[2])}</span>
							</div>
						</div>
					</div>
				</div>
				<div className='owsi__tasks'>Feladatok: {data.tasks.join(', ')}</div>
			</OurWorksSubsText>
			<Coffee />
		</Container>
	);
};

export default OurWoksSubsLayout;
