import axios from 'axios';

export const API_URL = 'https://api.itech.ilinedb.hu/contact';

export default async (data) => {
	const ContactFormData = new FormData();
	Object.keys(data).forEach((key) => {
		ContactFormData.append(key, data[key]);
	});

	try {
		const res = await axios({
			method: 'POST',
			url: API_URL,
			headers: { 'Content-Type': 'multipart/form-data' },
			data: ContactFormData,
		});

		if (res.status === 200) {
			return res.data;
		}
	} catch (error) {
		console.log(error);
		throw error;
	}
};
