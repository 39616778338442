export const first_title = 'Tervezés';
export const first_desc =
	'Először személyes találkozók során felmérjük és pontosítjuk a megfogalmazódott igényeid, melyekről aztán részletes brief készül. Utána összegezzük a projektre vonatkozó specifikációkat, így a kezedben lesz annak drótváza, majd ezek alapján részletes ajánlatot és projekt ütemezést adunk át számodra. Következő lépésként megtervezzük a terméket, végig közösen egyeztetve az üzleti érdeked fő szempontként figyelembe véve. Nagyon fontos feladat a UX/UI tervezés, ekkor kerülnek lefektetésre a maximális felhasználói élményt biztosító felhasználói felületek.';

export const second_title = 'Fejlesztés';
export const second_desc =
	'Elvégezzük a frontend (amit az ügyfeleid látnak), backend (minden, ami mögötte van), sitebuild, mobil fejlesztői feladatokat, bevonva téged is a közös munkába, így garantálva azt, hogy lehetőséged legyen mindig időben változtatni egy-egy sprint közben, amennyiben az még szükséges.';

export const third_title = 'Tesztelés';
export const third_desc =
	'A fejlesztés közbeni és természetesen a végső átadás előtti többszörös tesztelés biztosítja, hogy a végeredmény megfeleljen elvárásaidnak.';

export const fourth_title = 'Bevezetés';
export const fourth_desc =
	'A feladatunk közel sem ér véget az új weboldal, webáruház vagy applikáció átadásakor, hiszen fontos ezt az alkalmazást megtalálhatóvá tenni a potenciális vevőid számára. Ehhez nyújtunk még segítséget SEO, illetve online marketing terén.';
