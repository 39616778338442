import React from 'react';
import { WorksCardsCols } from '../../../styles/Site.style';
import WorksCardsItem from '../../../global/worksCards/WorksCardsItem';

const OurWorksCards = (props) => {
	const { works } = props;
	const leftSize = ['s', 'm'];
	const rightSize = ['m', 's'];

	const leftList = works.map((work, index) => {
		if (index % 2 === 0) {
			return (
				<WorksCardsItem size={leftSize.shift()} workItem={work} key={index} />
			);
		}
		return null;
	});

	const rightList = works.map((work, index) => {
		if (index % 2 !== 0) {
			return (
				<WorksCardsItem size={rightSize.shift()} workItem={work} key={index} />
			);
		}
		return null;
	});

	return (
		<>
			<WorksCardsCols float='left'>{leftList}</WorksCardsCols>
			<WorksCardsCols float='right'>{rightList}</WorksCardsCols>
		</>
	);
};

export default OurWorksCards;
