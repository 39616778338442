import styled, { css } from 'styled-components';
import * as theme from '../styles/Variables';

export const OurWorksSubsImages = styled.div`
	display: grid;
	grid-template: 3fr / 3fr 1fr;
	grid-gap: 40px;
	margin: 30px 0;
	.owsi {
		&__main {
			grid-row: 1 / 4;
		}
		&__sub_first {
			grid-row: 1 / 3;
		}
		&__main,
		&__sub_first,
		&__sub_second {
			img {
				border-radius: 50px;
				width: 100%;
				height: 100%;
			}
		}
	}
	@media (max-width: 992px) {
		grid-template: 1fr auto / 1fr 1fr;
		.owsi {
			&__main {
				grid-row: 1 / 2;
				grid-column: 1 / 3;
			}
			&__sub_first {
				grid-row: 2 / 3;
			}
			&__main,
			&__sub_first,
			&__sub_second {
				img {
					height: auto;
				}
			}
		}
	}
	@media (max-width: 600px) {
		grid-template: repeat(3, auto) / 1fr;
		.owsi {
			&__main {
				grid-row: 1 / 2;
				grid-column: 1 / 2;
			}
			&__sub_first {
				grid-row: 2 / 3;
			}
			&__main,
			&__sub_first,
			&__sub_second {
				img {
					border-radius: 20px;
				}
			}
		}
	}
	${(props) =>
		props.type !== 'web' &&
		css`
			grid-template: 1fr / auto auto;
			.owsi__main {
				grid-row: 1 / 1;
				grid-column-end: 1;
				grid-column: 1 / 1;
			}
			.owsi__sub_first {
				grid-row: 1 / 1;
			}
			@media (max-width: 992px) {
				grid-template: 1fr / 1fr 1fr;
				.owsi__sub_first {
					grid-row-start: 1;
				}
			}
			@media (max-width: 600px) {
				grid-template: 1fr 1fr / 1fr;
				.owsi__main {
					grid-row: 1 / 1;
				}
				.owsi__sub_first {
					grid-row: 2 / 2;
				}
			}
		`}
`;
export const OurWorksSubsText = styled.div`
	margin-bottom: 40px;
	.owsi {
		&__wrapper {
			display: grid;
			grid-template-rows: 1fr;
			grid-template-columns: 3fr 1fr;
			grid-gap: 30px;
		}
		&__lead,
		&__text__overview__lead {
			font-size: 30px;
			font-family: C-B;
			margin: 30px 0;
		}
		&__sub_lead {
			font-family: C-B;
			font-size: 24px;
			margin: 30px 0;
		}
		&__tasks {
			font-family: C-B;
			color: ${theme.primary_text};
		}
		&__text {
			&__simple p {
				color: ${theme.primary_text};
			}
			&__overview {
				&__flow {
					padding-left: 25px;
					&__first,
					&__second {
						&:after {
							width: 5px;
							height: 100%;
							content: '';
							position: absolute;
							background: ${theme.idxNumber};
							margin: auto;
							top: 50px;
							bottom: 0;
							left: -23px;
						}
					}
					&__first,
					&__second,
					&__third {
						position: relative;
						margin-bottom: 30px;
						p,
						span {
							font-family: C-B;
							font-size: 16px;
							margin: 0;
						}
						p {
							margin-bottom: 5px;
						}
						span {
							font-family: C-R;
						}
						&:before {
							width: 20px;
							height: 20px;
							content: '';
							position: absolute;
							border-radius: 50%;
							border: solid 3px ${theme.primary_color};
							top: 0;
							left: -30px;
						}
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		.owsi {
			&__wrapper {
				grid-template-rows: 1fr auto;
				grid-template-columns: 1fr;
				&--left {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
`;
