import React from 'react';
import { Link } from 'react-router-dom';
import WorksCardsItem from '../../../global/worksCards/WorksCardsItem';
import { FancyButton } from '../../../styles/Buttons.style';
import { WorksCardsCols } from '../../../styles/Site.style';

const WorksCard = (props) => {
	const { lastestWorks } = props;
	return (
		<>
			<WorksCardsCols float='left'>
				<WorksCardsItem size='s' workItem={lastestWorks[0]} />
				<WorksCardsItem size='m' workItem={lastestWorks[2]} />
			</WorksCardsCols>
			<WorksCardsCols float='right'>
				<WorksCardsItem size='m' workItem={lastestWorks[1]} />
				<WorksCardsItem size='s' workItem={lastestWorks[3]} />
			</WorksCardsCols>
			<FancyButton float='right' margin='40px 10px 0 0'>
				<Link to='/munkaink'>továbbiak {'>'}</Link>
			</FancyButton>
		</>
	);
};

export default WorksCard;
