import React from 'react';

const OurWorksTitle = () => {
	return (
		<>
			<div className='lead'>
				<p>Törekszünk a</p>
				<p>
					<strong>tökéletességre</strong>
				</p>
			</div>
			<div className='sub'>
				<p>
					Azért szeretünk ügyfeleinkkel dolgozni, mert ezzel mi is napról napra
					fejlődünk.
				</p>
			</div>
		</>
	);
};

export default OurWorksTitle;
