import React from 'react';
import {
	WorksCardsHoverStyle,
	WorksCardsHoverStyleCategory,
	WorksCardsHoverStyleTitle,
	WorksCardsHoverButtonWrapper,
} from '../../styles/Site.style';
import { Link } from 'react-router-dom';
import { SimpleBorderedButton } from '../../styles/Buttons.style';

const WorksCardHover = (props) => {
	const { link, title, tags } = props;
	return (
		<WorksCardsHoverStyle>
			<WorksCardsHoverStyleCategory>
				{/* arculatkészítés / webfejlesztés / UI / UX */}
				{tags.join(' / ')}
			</WorksCardsHoverStyleCategory>
			<WorksCardsHoverStyleTitle>
				{/* Debreceni Egyetem Atlétikai Club */}
				{title}
			</WorksCardsHoverStyleTitle>
			<WorksCardsHoverButtonWrapper>
				<SimpleBorderedButton>
					<Link to={`/munkaink/${link}`}>részletek</Link>
				</SimpleBorderedButton>
			</WorksCardsHoverButtonWrapper>
		</WorksCardsHoverStyle>
	);
};

export default WorksCardHover;
