import React, { useState, useEffect, useContext } from 'react';
import { Container, SimpleTitle, Tabs, Tab } from '../../styles/Site.style';
import {
	ServicesWrapper,
	StandingServices,
	LyingServices,
} from '../../styles/Services.style';
import ServicesTitle from './ServicesTitle';
import ServicesOuter from './ServicesOuter';
import ServicesOuterLying from './ServicesOuterLying';
import Coffee from '../../global/Coffee';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg-szolgaltatasok-top.png';
import ServicesStatic from './static/ServicesStatic.static';

const Services = (props) => {
	const siteBgContext = useContext(SiteBgContext);

	useEffect(() => {
		if (props.location.query) {
			switch (props.location.query.tab) {
				case 'web':
					set_active(1);
					break;
				case 'grafika':
					set_active(2);
					break;
				case 'marketing':
					set_active(3);
					break;
				default:
					break;
			}
		}
		siteBgContext.setActiveBg(BG);
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, []);

	const [active_service, set_active_service] = useState([]);
	const [active, set_active] = useState(1);

	useEffect(() => {
		const filteredServices = ServicesStatic.filter((st) => st.type === active);
		set_active_service(filteredServices);
		return () => {};
	}, [active]);

	const getStandingServices = () =>
		active_service.filter((s) => s.style === 'standing');

	const getLyingServices = () =>
		active_service.filter((s) => s.style === 'lying');

	return (
		<Container>
			<ServicesWrapper>
				<SimpleTitle>
					<ServicesTitle />
				</SimpleTitle>
				<Tabs>
					<Tab
						className={active === 1 && 'active'}
						onClick={() => set_active(1)}
					>
						Web&App fejlesztés
					</Tab>
					<Tab
						className={active === 2 && 'active'}
						onClick={() => set_active(2)}
					>
						Grafika
					</Tab>
					<Tab
						className={active === 3 && 'active'}
						onClick={() => set_active(3)}
					>
						Marketing
					</Tab>
				</Tabs>
				<StandingServices>
					{getStandingServices().map((service) => (
						<ServicesOuter key={service.number} {...service} />
					))}
				</StandingServices>
				<LyingServices>
					{getLyingServices().map((service) => (
						<ServicesOuterLying key={service.number} {...service} />
					))}
				</LyingServices>
			</ServicesWrapper>
			<Coffee />
		</Container>
	);
};

export default Services;
