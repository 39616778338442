import React from "react";
import NavbarState from '../../context/navbar/NavbarState';
import Navbar from '../../components/global/navbar/Navbar';
import Footer from '../../components/global/Footer';
import ScrollUpButtonComp from "./ScrollUpButtonComp";

const Layout = (props) => {
    return (
        <>
            <NavbarState>
                <Navbar />
                <ScrollUpButtonComp />
            </NavbarState>
            {props.children}
            <Footer />
        </>
    );
}

export default Layout;