import React from 'react';
import { LatestWorks } from '../../../styles/Home.style';
import LastestWorksCards from './LastestWorksCards';
import PerPattern from '../../../../assets/images/perpattern.png';
import { Title, Spacer, UnderlinedText } from '../../../styles/Site.style';
import referenceWorks from '../../../global/static/ReferenceWorks.static';

const LatestWorksComp = () => {
	const topFour = () => {
		const returnOrder = referenceWorks.sort((a, b) =>
			a.order > b.order ? 1 : -1
		);
		return returnOrder.slice(0, 4);
	};

	return (
		<LatestWorks>
			<Spacer height='80px'></Spacer>
			<Title>
				Friss&nbsp;
				<UnderlinedText perpattern={PerPattern}>munkáink</UnderlinedText>
				&nbsp;
				<div>
					<p>legutóbb ezeken</p>
					<p>dolgoztunk</p>
				</div>
			</Title>
			<LastestWorksCards lastestWorks={topFour()} />
		</LatestWorks>
	);
};

export default LatestWorksComp;
