import React from 'react';
import { FancyButton } from '../../styles/Buttons.style';
import { Link } from 'react-router-dom';
import { ServicesHoverFooterWrapper } from '../../styles/Services.style';

const ServicesHoverFooter = (props) => {
	return (
		<ServicesHoverFooterWrapper className={props.alt}>
			<p>
				Szükséged lenne egy hasonló projektre?
				{props.alt ? ' ' : <br />}
				Vedd fel velünk a kapcsolatot!
			</p>
			<FancyButton>
				<Link to='/kapcsolat'>írj nekünk</Link>
			</FancyButton>
		</ServicesHoverFooterWrapper>
	);
};

export default ServicesHoverFooter;
