import styled from 'styled-components';
import * as theme from './Variables.js';

export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
    font-family: C-B;
    font-size: 18px;

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 675px;
        padding: 0 25px;
    }
    .pageSelector {
        width: 40px;
        height: 40px;
        background-color: transparent;
        transition: border .2s ease-out;
    }
    
    .pageSelector:hover {
        background: transparent !important;
        border: 3px solid ${theme.primary_color};
        border-radius: 50%;
        display: inline;
        text-decoration: none;
        color: ${theme.primary_color} !important;
        box-shadow: 3px 3px 0px ${theme.deep_purple};
        position: relative;
        z-index: 1;
        transition: all 0.25s ease-in-out;
        outline: none;
    
    }
    
    .pageSelector:hover:after {
        transition: all 0.25s ease-in-out;
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50px;
        top: 3px;
        z-index: -1;
        box-shadow: -3px -3px 0px ${theme.blueish};
        left: 3px;
    }
    
    .activeSelector {
        border-radius: 50%;
        background-color: ${theme.blueish} !important;
        width: 40px;
        height: 40px;
        color: white;
    }
    
    .activeSelector:hover {
        border: none;
        box-shadow: none;
        background-color: ${theme.blueish} !important;
        color: white !important;
    }

    .nextPageButton {
        margin-left: 8vW;
    }
    
    .prevPageButton {
        margin-right: 8vW;
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;


export const OurWorksWrapper = styled.section`
    transition: transform 0.25s linear;
	transform: translateX(150%);
	max-height: 0;
	overflow-y: hidden;
	&.active {
		transform: translateX(0%);
		max-height: fit-content;
		overflow-y: visible;
	}
`;