import React, { useState, useEffect, useContext } from 'react';
import { Container, SimpleTitle, Tabs, Tab } from '../../styles/Site.style';
import OurWorksTitle from './OurWorksTitle';
import tabsTitle from './static/TabsTitle.static';
import Coffee from '../../global/Coffee';
import references from '../../global/static/ReferenceWorks.static';
import OurWorksComp from './OurWorksComp';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg-munkaink-top.png';

const OurWorks = () => {
	const siteBgContext = useContext(SiteBgContext);
	useEffect(() => {
		window.scrollTo(0, 0);
		siteBgContext.setActiveBg(BG);
		//eslint-disable-next-line
	}, []);
	const [activeTabNum, setActiveTabNum] = useState(0);
	const orderedReferences = references.sort((a, b) =>
		a.order > b.order ? 1 : -1
	);
	const ourWorksCompList = tabsTitle.map((tabTitle, index) => (
		<OurWorksComp
			key={index}
			filteredReferences={orderedReferences.filter((reference) => {
				if (index === 0) {
					return reference;
				} else if (reference.category === tabTitle) {
					return reference;
				}
				return null;
			})}
			active={index === activeTabNum}
		/>
	));

	const tabList = tabsTitle.map((text, index) => (
		<Tab
			key={index}
			className={activeTabNum === index && 'active'}
			onClick={() => setActiveTabNum(index)}
		>
			{text}
		</Tab>
	));

	return (
		<>
			<Container>
				<SimpleTitle>
					<OurWorksTitle />
				</SimpleTitle>
				<Tabs id='scrollHere'>{tabList}</Tabs>
				{ourWorksCompList}
				<Coffee />
			</Container>
		</>
	);
};

export default OurWorks;
