import React, { useState, useEffect } from 'react';
import { SingleService } from '../../styles/Services.style';
import { FancyButton } from '../../styles/Buttons.style';
import HTMLParser from 'html-react-parser';
import ServicesHover from './ServicesHover';

const ServicesOuterLying = (props) => {
	const [hover, set_hover] = useState(false);
	const closeClick = () => set_hover(false);
	useEffect(() => {
		return () => {
			set_hover(false);
		};
	}, [props]);
	return (
		<SingleService className={hover}>
			<div className='service__image'>
				<img src={props.image} alt='' />
			</div>
			<div className='service__text-wrapper'>
				<div className='service__title'>
					<span className='service__number'>{props.number}</span>
					<p>{props.title}</p>
				</div>
				<div className='service__text'>{props.text}</div>
				<div className='service__text service__text--regular'>
					{HTMLParser(props.under_image_text)}
				</div>
				<FancyButton onClick={() => set_hover(true)}>
					<button>bővebben</button>
				</FancyButton>
			</div>
			{hover && (
				<ServicesHover hover closeClick={closeClick} data={props} alt />
			)}
		</SingleService>
	);
};

export default ServicesOuterLying;
