import React from 'react';
import { WhiteContentBox } from '../../styles/Site.style';
import {
	first_title,
	first_desc,
	second_title,
	second_desc,
	third_title,
	third_desc,
	fourth_title,
	fourth_desc,
} from './static/Dropdown.static';
import AboutUsDropdownItem from './AboutUsDropdownItem';

const AboutUsDropdown = () => {
	return (
		<WhiteContentBox>
			<AboutUsDropdownItem
				num='01.'
				lead={first_title}
				desc={first_desc}
			></AboutUsDropdownItem>
			<AboutUsDropdownItem
				num='02.'
				lead={second_title}
				desc={second_desc}
			></AboutUsDropdownItem>
			<AboutUsDropdownItem
				num='03.'
				lead={third_title}
				desc={third_desc}
			></AboutUsDropdownItem>
			<AboutUsDropdownItem
				num='04.'
				lead={fourth_title}
				desc={fourth_desc}
			></AboutUsDropdownItem>
		</WhiteContentBox>
	);
};

export default AboutUsDropdown;
