import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import OurWorksSubs from './static/OurWorksSubs.static';
import OurWoksSubsLayout from './OurWoksSubsLayout';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg-munkaink-belso-top.png';

const OurWorksSubsSwitch = (props) => {
	const siteBgContext = useContext(SiteBgContext);

	const history = useHistory();

	const [data, set_data] = useState([]);

	useEffect(() => {
		const foundObj = findRoute();
		if (!foundObj) history.push('/munkaink');
		set_data(foundObj);
		window.scrollTo(0, 0);
		siteBgContext.setActiveBg(BG);
		//eslint-disable-next-line
	}, []);

	const findRoute = () => {
		const slug = props.match.params.slug;
		const findObj = OurWorksSubs.filter((obj) => obj.link === slug);
		if (findObj && findObj.length !== 0) return findObj[0];
		return false;
	};

	if (data.length === 0) return null;

	return <OurWoksSubsLayout data={data}></OurWoksSubsLayout>;
};

export default OurWorksSubsSwitch;
