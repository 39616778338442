import styled from 'styled-components';
import * as theme from './Variables';

export const ServicesWrapper = styled.section``;

export const SingleServiceWrapper = styled.div`
	transition: transform 0.25s linear;
	transform: translateX(150%);
	max-height: 0;
	overflow-y: hidden;
	&.active {
		transform: translateX(0%);
		max-height: fit-content;
		overflow-y: visible;
	}
`;

export const SingleService = styled.div`
	transition: all 0.5s ease-in-out;
	background: white;
	border-radius: 50px;
	box-shadow: 0px 60px 100px 0px rgba(61, 67, 79, 0.1);
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	line-height: 1.4;
	&.true {
		.service {
			&__image,
			&__text-wrapper {
				visibility: hidden;
				opacity: 0;
				max-width: 0;
				max-height: 0;
				width: 0;
				height: 0;
			}
		}
	}
	.service {
		&__image,
		&__text-wrapper {
			transition: opacity 0.5s ease-out, visibility 0s ease-out;
			visibility: visible;
			opacity: 1;
		}
		&__title {
			display: flex;
			position: relative;
			justify-content: center;
			align-items: center;
			margin: 40px 0;
			z-index: 0;
			width: 100%;
			p {
				font-size: 30px;
				font-family: C-B;
				position: relative;
			}
		}
		&__number {
			position: absolute;
			z-index: -1;
			top: -100px;
			margin: 0 auto;
			font-size: 140px;
			color: ${theme.idxNumber};
			font-family: PFD-B;
			width: 100%;
			text-align: center;
		}
		&__text {
			font-size: 16px;
			font-family: C-B;
			margin: 40px 0;
			&--regular {
				font-family: C-R;
				color: ${theme.primary_text};
			}
		}
	}
`;

export const StandingServices = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 0 80px 0;
	${SingleService} {
		.service__text-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
		}
		max-width: 48%;
		@media (max-width: 768px) {
			max-width: 100%;
			margin: 20px 0;
		}
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const LyingServices = styled.div`
	${SingleService} {
		max-width: 100%;
		flex-direction: row;
		margin-bottom: 80px;
		overflow-y: hidden;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		.service {
			&__image,
			&__text-wrapper {
				text-align: left;
				max-width: 50%;
				@media (max-width: 768px) {
					max-width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
				}
			}
			&__title {
				justify-content: flex-start;
			}
			&__number {
				text-align: left;
			}
		}
	}
`;

export const ServiceHoverWrapper = styled.div`
	max-width: 100% !important;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 40px 0;
	.service {
		&__close {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
			img {
				cursor: pointer;
			}
		}
	}
`;

export const ServicesHoverFooterWrapper = styled.div`
	p {
		font-family: C-B;
		font-size: 26px;
		margin: 50px 0;
	}
	&.true {
		display: flex;
		justify-content: space-between;
		p {
			margin: 50px 0 0 0;
		}
		align-items: flex-end;
	}
	@media (max-width: 992px) {
		&.true {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			p {
				margin-bottom: 30px;
			}
		}
	}
`;
