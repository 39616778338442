import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SitePattern, SiteBG } from './components/styles/Site.style';
import Services from './components/pages/services/Services';
import pattern from './assets/images/pattern.png';
import Home from './components/pages/home/Home';
import ContactUs from './components/pages/contactUs/ContactUs';
import AboutUs from './components/pages/aboutUs/AboutUs';
import OurWorks from './components/pages/ourWorks/OurWorks';
import OurWorksSubsSwitch from './components/pages/ourWorksSubs/OurWorksSubsSwitch';

import Layout from './components/global/Layout';
import SiteBgContext from './context/site_bg/siteBgContext';

const App = () => {
	const siteBgContext = useContext(SiteBgContext);
	const [site_bg, set_site_bg] = useState();
	useEffect(() => {
		set_site_bg(siteBgContext.site_bg);
	}, [siteBgContext.site_bg]);
	return (
		<Router>
			<Switch>
				<SitePattern pattern={pattern}>
					<SiteBG bg={site_bg}>
						<Layout>
							<Route exact path='/' component={Home} />
							<Route exact path='/rolunk' component={AboutUs} />
							<Route exact path='/kapcsolat' component={ContactUs} />
							<Route exact path='/szolgaltatasok' component={Services} />
							<Route exact path='/munkaink' component={OurWorks} />
							<Route
								exact
								path='/munkaink/:slug'
								component={OurWorksSubsSwitch}
							/>
						</Layout>
					</SiteBG>
				</SitePattern>
			</Switch>
		</Router>
	);
};

export default App;
