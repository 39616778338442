import WebImg from '../../../../assets/images/services/service-web-big.png';
import WebShopImg from '../../../../assets/images/services/service-webshop-big.png';
import MobileImg from '../../../../assets/images/services/service-mobile-app.png';
import LandingImg from '../../../../assets/images/services/service-landing.png';
import WebDesignImg from '../../../../assets/images/services/service-webdesign.png';
import LogoImg from '../../../../assets/images/services/service-logo.png';
import FlupImg from '../../../../assets/images/services/service-flup.png';
import OfflineImg from '../../../../assets/images/services/service-offline.png';
import AdImg from '../../../../assets/images/services/service-adwords.png';
import SeoImg from '../../../../assets/images/services/service-seo.png';

export default [
	{
		type: 1,
		number: '01.',
		style: 'standing',
		title: 'Weboldal készítés',
		text:
			'Több éves honlapkészítési tapasztalattal a hátunk mögött biztosítunk róla, hogy átgondolt, jól megtervezett weboldal készítési folyamataink meghozzák a sikert számodra is!',
		image: WebImg,
		under_image_text:
			'Ha alkumentes honlapkészítési megoldásra van szükséged és a maximumot szeretnéd kihozni ötleteidből, úgy ezen céljaidra egyedi fejlesztési megoldásainkat ajánljuk!',
		hover:
			'Egyedi weboldalaink előnye, hogy sokkal hatékonyabban fejleszthetőek tovább egy előre nem látott ötlet vagy későbbi igény felmerülésekor, továbbá nagyobb biztonságot kínálnak és jobb organikus találati eredményeket érnek el a különböző keresőkben is. Agilis módszertanunknak köszönhetően a fejlesztéseinknél mindig a legoptimálisabb megoldásokra törekszünk és azokat a legmodernebb technológiákkal valósítjuk meg.<br /><br />Célunk, hogy a weboldalkészítés folyamán olyan egyedi megoldásokat készítsünk számodra, ami miatt nem csak elégedett leszel, de ajánlanál is minket partnereidnek, ismerőseidnek. Számunkra a gól mindig a hosszú távú elégedettség és harmonikus együttműködés elérése. Éppen ezért igyekszünk mindent nem csak fejlesztői, de ügyfél szempontok alapján is megvizsgálni.',
	},
	{
		type: 1,
		number: '02.',
		style: 'standing',
		title: 'Webáruház készítés',
		text:
			'A megnövekedett online jelenlét miatt mobilok előtérbe kerülésével a webáruház készítés a kereskedelem új korszakába lépett.',
		image: WebShopImg,
		under_image_text:
			'Nagy rutinnal rendelkezünk az egyszerű és komplex, egyedi fejlesztésű több mögöttes rendszer integrációját igénylő webshop készítésben is.',
		hover:
			'Webshopok, webáruházak és számos eCommerce megoldások szolgáltatása ügyfeleid részére, legyen szó bármilyen egyedi ötletről. Webshopjaink nem csak felhasználóbarátok, de átlátható és könnyen kezelhető admin felülettel is rendelkeznek.<br /><br />Webshop fejlesztéseink a kisebb, néhány terméket kezelő egészen az akár több tízezer terméket kezelő webshopig mindent lefednek, továbbá ezekhez kapcsolódó minden háttérszolgáltatást is.<br /> <br />Pl.: hírlevél küldő rendszerek, fizetési integrációk (OTPay, Barion, PayPal stb. stb..)',
	},
	{
		type: 1,
		number: '03.',
		style: 'lying',
		title: 'Mobil App fejlesztés',
		text: 'A kezünkben a jövő!',
		image: MobileImg,
		under_image_text:
			'Akár natív Android, iOS vagy akár költséghatékony Cross Platform mobil applikáció fejlesztésről legyen szó, mi állunk rendelkezésedre!',
		hover:
			'Fejlesztéseink során mindig a legfejlettebb technológiai irányokból indulunk ki. A React Native aktív alkalmazása mellett alkalmazásainkat Swift, Objective-C és Java kódok is tarkítják. A dizájnt a jól átgondolt felhasználói élmény és a „mobile first” szemlélet adja, így mind Androidon, mind iOS-en biztosítjuk a zavartalan megjelenítést.',
	},
	{
		type: 1,
		number: '04.',
		style: 'lying',
		title: 'Landing oldalak készítése',
		text: '',
		image: LandingImg,
		under_image_text:
			'Egyre sűrűbben fordul elő egy vállalat életében, hogy egy-egy újonnan megjelenő terméke számára érdemes egy teljesen különálló oldalt készíteni, amely kizárólagosan az új termékre fókuszál, mindenféle kötöttség nélkül. Ilyen esetekre ajánljuk például az alacsonyabb költségvetésű landing oldalak készítését.',
		hover:
			'Az úgynevezett microsite-ok, azaz landing oldalak kifejezetten hasznosak lehetnek kereskedelemmel vagy szolgáltatással foglalkozó vállalatok számára, amikor az elsődleges cél egy új termékük vagy szolgáltatásuk minél szélesebb körben történő megismertetése. Az ilyen oldalak hatékonysága a rájuk építhető összetett online marketing kampányok segítségével még tovább növelhető.<br /><br />Miért érdemes Landing oldal egy-egy kampányhoz?<br /><br />Egyszerű, lineáris felépítésű.<br /><br />Nem tud elkolbászolni elkalandozni az érdeklődő az érkezési oldalról.<br /><br />Kiemelheted a termék egyedi vonzásjegyeit.<br /><br />Elhelyezhetők a bizalomépítő elemek (pl. médiamegjelenések, garancia, ajánlások, ügyfélvélemények, tanúsítványok, szakmai díjak, előnyök stb.).<br /><br />Rendelkezik CTA-val.<br /><br />Egyértelmű, hogy mi az ajánlat, és mit kell tennie az érdeklődőnek, ha érdekli a termék.<br /><br />A konverziómérés beállítható, így a kampány teljesítménye mérhető lesz.',
	},
	{
		type: 2,
		number: '01.',
		style: 'standing',
		title: 'Webdesign készítés, redesign',
		text:
			'Egy weboldal esetében nagyon sok múlik azon, hogy vizuálisan is megnyerő legyen. Sok esetben ugyanis ezen dől el, hogy valaki ügyfeleddé válik-e vagy bezárja az oldalt. ',
		image: WebDesignImg,
		under_image_text:
			'Design megoldásaink minden esetben az igényeidnek megfelelően készülnek el, szem előtt tartva az aktuális trendeket. Ebben tudunk bármikor a segítségedre lenni:',
		hover:
			'Új weboldalak reszponzív és adaptív grafikai tervezése.<br/><br/>Lenyűgöző, a márkához illeszkedő design.<br/><br/>Meglévő weboldalak grafikai újragondolása a jelenleg is használatban lévő meghatározó arculati elemek meghagyásával<br/><br/>UI/UX tervezés<br/><br/>UI Kit tervezés és készítés, igény szerint<br/><br/>Élő prototípus készítése',
	},
	{
		type: 2,
		number: '02.',
		style: 'standing',
		title: 'Logo -, kis - és nagyarcualt tervezés',
		text:
			'A Logó maga a cég. Helyes és megfelelő kialakítása meghatározó, hiszen megalkotása után ezzel azonosítanak majd ügyfeleid.',
		image: LogoImg,
		under_image_text: 'Logo tervezési szolgáltatásaink:',
		hover:
			'Logo tervezés<br/><br/>Logo ReDesign<br/><br/>Kisarculat<br/><em>logó és névjegykártya készítés</em><br/><br/>Nagyarculat<br/><em>Logó, névjegykártya, levélpapír, mappa, boríték, jegyzetfüzet készítés</em>',
	},
	{
		type: 2,
		number: '03.',
		style: 'lying',
		title: 'Kiadványszerkesztés, magazin tervezés',
		text: '',
		image: FlupImg,
		under_image_text:
			'Legyen szó akár újságról, magazin tervezéséről mi gondoskodunk arról, hogy az nyomdakész állapotba kerüljön és valóban úgy nézzen ki nyomtatva is, ahogy azt elképzelted.',
		hover:
			'Borító tervezés<br/><br/>Magazinok, újságok, útmutatók<br/><br/>Tördelés',
	},
	{
		type: 2,
		number: '04.',
		style: 'lying',
		title: 'Offline megjelenés',
		text: '',
		image: OfflineImg,
		under_image_text:
			'Az online világ terjedése mellett még mindig vannak olyan helyzetek, amikor az ismertség növelése érdekében egy megfelelő katalógus, molinó vagy szórólap elengedhetetlen.',
		hover:
			'<strong>Miben is tudunk segíteni?</strong><br/><br/>Szórólap tervezés<br/>Óriásplakát grafikai tervezés<br/>Épületháló grafikai tervezés<br/>Katalógusok grafikai tervezése<br/>Bemutatkozó prezentációk készítése<br/>Mappa tervezése és készítése<br/>Molinó tervezés<br/>Bármilyen stanc rajzra való grafikai tervezés',
	},
	{
		type: 3,
		number: '01.',
		style: 'standing',
		title: 'Adwords, Facebook, Instagram',
		text:
			'Amikor egy úgy döntesz, hogy online marketing eszközökkel népszerűsíted tevékenységed, számos komplex alternatívában gondolkodhatsz. ',
		image: AdImg,
		under_image_text:
			'A Google Adwords szofisztikáit hirdetési rendszerétől kezdve egészen a Facebook cross- device hatékonyságára és közösségi erejére építő lehetőségein keresztül a különböző platformokon működő, komplex remarketingig rengeteg módszer áll rendelkezésre.',
		hover:
			'<strong>Google AdWords:</strong><br/>Hirdetői piac áttekintése,<br/>AdWords fiók létrehozása / átépítése, Google-fiókok összekapcsolása, Kulcsszókutatás, kulcsszólista kialakítása, Kampányok és hirdetéscsoportok kialakítása, Hirdetési szövegek megírása,<br/>Mérőkódok és remarketing listák beállítása<br/>Fiók grafikai tartalmának kialakítása<br/>Kampányok folyamatos optimalizációja<br/>Képi és szöveges anyagok létrehozása, rendszerezése, szerkesztése és közzé tevése<br/><br/><strong>Facebook / Instagram kampány:</strong><br/>Tartalomkezelés<br/>Fiók grafikai tartalmának kialakítása<br/>Havi min. 1 általunk írt blog poszt közzététele<br/>Havi min. 8 post megírása, közzététele<br/>Mérőkódok és remarketing listák beállítása<br/>Kampányok és hirdetéscsoportok kialakítása, Hirdetési szövegek megírása,<br/>Kampányok folyamatos optimalizációja<br/>Képi és szöveges anyagok létrehozása, rendszerezése, szerkesztése és közzé tevésé közzététele',
	},
	{
		type: 3,
		number: '02.',
		style: 'standing',
		title: 'Keresőoptimalizálás',
		text:
			'A keresőoptimalizálás mára elengedhetetlen része lett láthatóságnak. A Google keresőben megfelelő kulcsszavak használatával még több potenciális ügyfelet tudunk oldaladra csalogatni.',
		image: SeoImg,
		under_image_text: 'Így állunk neki: ',
		hover:
			'Kulcsszókutatás<br/><em>(milyen kifejezésekkel keresik a termékeid, szolgáltatásaid)</em><br/><br/>Versenytárselemzés<br/><em>(kik vannak az organikus lista elején)</em><br/><br/>SEO szövegírás a megtalált kulcsszavakra.<br/><br/>Google helyezés ellenőrzés<br/><br/>Prémium linképítés',
	},
];
