import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Normalize from 'react-normalize';
import SiteBgState from './context/site_bg/SiteBgState';

ReactDOM.render(
	<>
		<Normalize />
		<SiteBgState>
			<App />
		</SiteBgState>
	</>,
	document.getElementById('root')
);
